/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiFile } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Modal } from '../../components/Modal';
import { api } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import IntlCurrencyInput from 'react-intl-currency-input';
import fileImage from '../../assets/img/file.jpg';

import * as Yup from 'yup';

const currencyConfig = {
	locale: 'pt-br',
	formats: {
		number: {
			BRL: {
				style: 'currency',
				currency: 'BRL',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			},
			USD: {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			},
			BTC: {
				style: 'currency',
				currency: 'XBT',
				minimumFractionDigits: 2,
				maximumFractionDigits: 8,
			},
		},
	},
};

interface IProps {
	product: any;
	updateProduct: Function;
}

const validateSchema = Yup.object({
	name: Yup.string().min(5).required(),
	description: Yup.string().min(50).required(),
});

export function PlanInfo({ product: plan, updateProduct: updatePlan }: IProps) {
	const { toggleLoading } = useContext(AuthContext);

	const [loading, setLoading] = useState(false);
	const [modalUpdate, setModalUpdate] = useState(false);
	const [pin, setPin] = useState('');
	const [otpAuth, setOtpAuth] = useState('');
	const [img, setImg] = useState<any>();
	const [imgName, setImgName] = useState('');
	const [inputPrice, setInputPrice] = useState(0.0);

	const { register, getValues, reset, setValue } = useForm<any>({
		mode: 'onSubmit',
		reValidateMode: 'onBlur',
	});

	// --- Create product --- //
	async function handleCreatePlan(data: any) {
		// --- Validate input --- //
		if (inputPrice < 0) {
			toast(`O valor do produto deve ser maior que 0`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			return;
		}
		if (!img) {
			toast(`Não foi possivel encontrar sua imagem`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			return;
		}
		if (data?.status !== 'a' && data?.status !== 'i') {
			toast(`O status do produto é invalido`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			return;
		}
		toggleLoading(true);
		setLoading(true);
		data.file = JSON.stringify({ base64: img });
		data.price = inputPrice;

		try {
			await api.post('/plans/manager/create', data);
			toast(`Plano criado com sucesso com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			setModalUpdate(false);
			reset();
			setImgName('');
			setImg(null);
			setInputPrice(parseFloat(String(0)));
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
		toggleLoading(false);
	}

	// --- Create product --- //
	async function handleUpdatePlan(data: any) {
		// --- Validate input --- //
		if (inputPrice < 0) {
			toast(`O valor do produto deve ser maior que 0`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			return;
		}
		if (!img) {
			toast(`Não foi possivel encontrar sua imagem`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			return;
		}
		if (data?.status !== 'a' && data?.status !== 'i') {
			toast(`O status do produto é invalido`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			return;
		}

		toggleLoading(true);
		setLoading(true);

		if (img && imgName) {
			data.file = JSON.stringify({ base64: img });
		}
		data.price = inputPrice;

		try {
			const response = await api.post('/plans/manager/update', { ...data, plan: data.planId });
			toast(`Plano atualizado com sucesso com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			setModalUpdate(false);
			updatePlan({ ...response.data, planId: response.data.id, copy: false });
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
		toggleLoading(false);
	}

	const imageHandler = (e: any) => {
		const reader = new FileReader();
		reader.onload = () => {
			if (reader?.readyState === 2) {
				setImg(reader.result);
			}
		};
		setImgName(e?.target?.files[0]?.name);
		reader.readAsDataURL(e?.target?.files[0]);
	};

	// --- Get image by url and convert in base64 --- //
	function toDataUrl(url: string, callback: Function) {
		var xhr = new XMLHttpRequest();
		xhr.onload = function () {
			var reader = new FileReader();
			reader.onloadend = function () {
				callback(reader.result);
			};
			reader.readAsDataURL(xhr.response);
		};
		xhr.open('GET', url);
		xhr.responseType = 'blob';
		xhr.send();
	}

	useEffect(() => {
		if (plan && plan?.image) {
			if (plan?.copy === true) {
				toDataUrl(plan?.image, setImg);
			} else {
				setImg(plan?.image);
			}
			setImgName('');
			reset({
				...plan,
				planId: plan?.copy === true ? null : plan?.id,
				id: plan?.copy === true ? null : plan?.id,
			});
			setInputPrice(parseFloat(String(plan?.price || 0)));
		}
	}, [plan]);

	return (
		<>
			<div className='content-c active' id='content-2'>
				<div className='content'>
					<h1>{plan?.name && plan?.copy !== true ? plan?.name : 'Criar plano'}</h1>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							setModalUpdate(true);
						}}
						className='form'
						acceptCharset='utf-8'
						method='post'
					>
						<div className='table-c'>
							<table className='table'>
								<tbody>
									<tr>
										<td colSpan={2} className='align-left' style={{ borderWidth: 1 }}>
											Imagens do produto
										</td>
									</tr>
									<tr>
										<td colSpan={2} className='align-left' style={{ borderWidth: 1 }}>
											<div className='form'>
												<div className='input file wd-20'>
													<label htmlFor='file-1' style={{ cursor: 'pointer' }}>
														<img id='imageId' src={img || fileImage} className='img' style={{ borderColor: '#555', borderWidth: 1, height: '100%', width: '100%', position: 'relative' }} />
													</label>
													<input id='file-1' type='file' className='input-f' accept='image/*' onChange={imageHandler} />
													<label htmlFor='file-1' className='file-name'>
														<FiFile className='icon' />
														<label htmlFor='file-1' className='text'>
															{imgName ? (imgName.length > 25 ? `${imgName.split('.')[0].substring(0, 25)}...*.${imgName.split('.')[1]}` : imgName) : 'Selecionar outra imagem'}
														</label>
													</label>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='input-c'>
							<input type='text' title='Nome' required className='input wd-75' placeholder='Nome' minLength={5} {...register('name')} />
							<select {...register('status')} required title='Status do usuário' className='select wd-33' placeholder='Status'>
								<option value='a'>Ativo</option>
								<option value='i'>Inativo</option>
							</select>
						</div>
						<div className='input-c'>
							<IntlCurrencyInput
								type='text'
								id='price'
								name='price'
								config={currencyConfig}
								currency='BRL'
								value={parseFloat(String(inputPrice))}
								style={{ color: '#000' }}
								onChange={(e: any, val: any) => {
									setInputPrice(parseFloat(val));
								}}
								className='input wd-100'
								required
							/>
							<input type='number' title='Lances' required className='input wd-33' placeholder='Lances' min={1} {...register('bets')} />
						</div>
						<textarea title='Descrição do produto' className='input wd-100' placeholder='Descrição' style={{ minHeight: 120 }} minLength={3} {...register('description')} />
						<div className='buttons-c'>
							<input type='submit' value={plan?.id ? 'ATUALIZAR' : 'CRIAR'} className='submit' />
							{plan?.id && (
								<input
									type='button'
									onClick={() => {
										reset();
										setValue('id', null);
										setValue('copy', true);
										setValue('planId', null);
										updatePlan({ ...getValues() });
									}}
									value='LIMPAR CAMPOS'
									className='submit'
									style={{ marginLeft: 10 }}
								/>
							)}
						</div>
					</form>
				</div>
			</div>
			<Modal
				handler={modalUpdate}
				onRequestClose={() => {
					setModalUpdate(false);
					setPin('');
					setOtpAuth('');
				}}
			>
				<form
					className='form'
					onSubmit={(e) => {
						e.preventDefault();
						const values = getValues();
						console.log(plan);
						console.log(!plan?.id || plan?.copy === true);
						if (!plan?.id || plan?.copy === true) {
							handleCreatePlan(getValues());
						} else if (values?.planId) {
							handleUpdatePlan(values);
						} else {
						}
					}}
					style={{ alignItems: 'center' }}
				>
					<div>
						<label className='label' style={{ color: '#fff' }} htmlFor='pin'>
							Senha
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<input type='password' id='pin' className='cnt-input' {...register('pin')} />
						</div>
						<label className='label' style={{ color: '#fff' }}>
							2FA
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto' }}>
							<input className='cnt-input' type='text' id='token' {...register('otpAuth')} />
						</div>

						{loading && (
							<svg xmlns='http://www.w3.org/2000/svg' style={{ margin: '0 auto', display: 'block', shapeRendering: 'auto' }} width='100px' height='60px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
								<path
									fill='none'
									stroke='#ffffff'
									strokeWidth='8'
									strokeDasharray='42.76482137044271 42.76482137044271'
									d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z'
									strokeLinecap='round'
									style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}
								>
									<animate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0;256.58892822265625'></animate>
								</path>
							</svg>
						)}
						{!loading && (
							<div className='buttons-c'>
								<input type='submit' disabled={loading} value={plan?.planId ? 'ATUALIZAR PRODUTO' : 'CRIAR PRODUTO'} className='submit' />
							</div>
						)}
					</div>
				</form>
			</Modal>
		</>
	);
}
