/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Modal } from '..';
import { api } from '../../../services/api';
import Select from 'react-select';
import { AuthContext } from '../../../contexts/AuthContext';
import { settings } from '../../../services/settings';
import dayjs from 'dayjs';
import IntlCurrencyInput from 'react-intl-currency-input';

const currencyConfig = {
	locale: 'pt-br',
	formats: {
		number: {
			BRL: {
				style: 'currency',
				currency: 'BRL',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			},
			USD: {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			},
			BTC: {
				style: 'currency',
				currency: 'XBT',
				minimumFractionDigits: 2,
				maximumFractionDigits: 8,
			},
		},
	},
};


interface ModalProps {
  handler: boolean;
  overlayOpacity?: number;
  onRequestClose: () => void;
  noPadding?: boolean;
  noClose?: boolean;
  width?: number;
  auction?: any
}

let timeOut: NodeJS.Timeout | null = null;
export function CreateAuctionModal({ 
  handler, 
  overlayOpacity, 
  onRequestClose, 
  noPadding, 
  noClose = false, 
  width,
  auction,
}: ModalProps) {
  const { toggleLoading, loading } = useContext(AuthContext);
  const [products, setProducts] = useState<any[]>([]);

  const { register, reset, getValues, setValue } = useForm<any>();


  async function searchProducts(search: string) {
    try {
      const response = await api.post("/products/manager/search", {search: search, page: 1})
      if (response.data.length < 1) {
        setProducts([])
      }
      const list = []
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        element.label = element.name;
        element.value = element.id;
        list.push(element)
      }
      setProducts(list)
    } catch (err: any) {
      toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: "var(--red-1)", color: "#fff" },
				progressStyle: { background: "darkred" },
			});
    }
  }

  async function handleCreateAuction(data: any) {
    if (data?.dateStart) {
      data.dateStart = dayjs(new Date(data.dateStart)).utc().format()
    }
    if (!data?.product) {
      toast(`Produto invalido`, {
        style: { backgroundColor: "var(--red-1)", color: "#fff" },
        progressStyle: { background: "darkred" },
      });
      return;
    }
    toggleLoading(true)
    try {
      await api.post("/manager/auction/create", data)
      toast(`Leilão criado com sucesso`, {
        style: { backgroundColor: "#00A300", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
      reset()
      onRequestClose()
    } catch(err: any) {
      toast(`${err?.response?.data?.message}`, {
        style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
    }
    toggleLoading(false)
  }

  async function handleEditAuction(data: any) {
    if (data?.dateStart) {
      data.dateStart = dayjs(new Date(data.dateStart)).utc().format()
    }
    toggleLoading(true)
    try {
      await api.post("/manager/auction/update", {...data, auctionId: auction?.id})
      toast(`Leilão editado com sucesso`, {
        style: { backgroundColor: "#00A300", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
      reset()
      onRequestClose()
      // setAuctionEdit(null)
      // if (page == 1) {
      //   searchAuctions()
      // } else {
      //   setPage(1)
      // }
    } catch(err: any) {
      toast(`${err?.response?.data?.message}`, {
        style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
    }
    toggleLoading(false)
  }

  useEffect(() => {
    if (auction?.id) {
      reset({
        timer: auction?.timer,
        product: auction?.product,
        dateStart: dayjs(auction?.dateStart).format('YYYY-MM-DDT08:08'),
        mark: auction?.mark,
        markPercentage: auction?.markPercentage,
        betsUse: auction?.betsUse,
        status: auction?.status,
        maxPrice: auction?.maxPrice,
      })
    }
  }, [auction])

  return(
    <Modal
        handler={handler}
        onRequestClose={onRequestClose}
        overlayOpacity={overlayOpacity}
        noPadding={noPadding}
        noClose={noClose}
        width={100}
      >
        {auction?.id && <h1 style={{color: "#fff", fontSize: 25, marginBottom: 10}}>Leilão {auction?.id}</h1>}
        <form className='form' onSubmit={(e) => {
          e.preventDefault()
          if (auction?.id) {
            handleEditAuction(getValues())
          } else {
            handleCreateAuction(getValues())
          }
          }} style={{alignItems: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            {!auction?.id && 
            <>
              <label className='label' style={{color: "#fff"}} htmlFor="product">Produto</label>
              <div style={{ width: '100%', margin: '0 auto', marginBottom: 15 }}>
                <Select
                  classNamePrefix="react-select"
                  className="react-select__container"
                  placeholder="SELECIONE O PRODUTO"
                  isClearable={true}
                  options={products}
                  formatOptionLabel={product => (
                    <div className="selector-box">
                      <div className="img-c">
                        <img src={product?.image} alt="product-image" />
                      </div>
                      <div className="label">
                        <h1>{String(product?.label).length < 25 ? product?.label : (String(product?.label).substring(0, 23) + "...")}</h1>
                        <span className="price">{product?.priceFormat}</span>
                        <span>{String(product?.description).length < 100 ? product?.description : (String(product?.description).substring(0, 100) + "...")}</span>
                      </div>
                    </div>
                  )}
                  onInputChange={(e) => {
                    if (timeOut != null) {
                      clearTimeout(timeOut)
                    }
                    timeOut = setTimeout(() => searchProducts(e), 1000)
                  }}
                  onChange={(e) => {
                    setValue('product', e?.value)
                    const product = products.find((p) => p?.value == e?.value)
                    setValue("maxPrice", parseFloat(`${product.price}`))
                  }}
                />
              </div>
            </>
            }
          <label className='label' style={{color: "#fff"}} htmlFor="dateStart" >Data de início</label>
            <div style={{ width: '100%', margin: '0 auto', marginBottom: 15 }}>
              <input
                id="dateStart"
                className='cnt-input' 
                style={{ width: '100%', maxWidth: 'none' }}
                type="datetime-local"
                required
                {...register('dateStart')}
              />
            </div>
            {
              auction?.id &&
              <>
                <label className='label' style={{color: "#fff"}} htmlFor="status" >Status</label>
                <div style={{ width: '100%', margin: '0 auto', marginBottom: 15 }}>
                  <select className='cnt-input' style={{width: '100%', maxWidth: 'none', borderRadius: 8}} {...register('status')}>
                    <option value="a">Ativado</option>
                    <option value="i">Desabilitado</option>
                  </select>
                </div>
              </>
            }
            {
              settings.mark &&
              <>
                <label className='label' style={{color: "#fff"}} htmlFor="referral" >Corretagem</label>
                <div style={{ width: '100%', margin: '0 auto', marginBottom: 15 }}>
                  <select className='cnt-input' style={{width: '100%', maxWidth: 'none', borderRadius: 8}} {...register('mark')}>
                    <option value="y">Ativada</option>
                    <option value="n">Desabilitada</option>
                  </select>
                </div>
              </>
            }
            {
              settings.mark && auction?.mark === "y" &&
              <>
                <label className='label' style={{color: "#fff"}} htmlFor="markPercentage" >% do corretor</label>
                <div style={{ width: '100%', margin: '0 auto', marginBottom: 15 }}>
                  <input
                    id="referralPercentage"
                    className='cnt-input' 
                    style={{ width: '100%', maxWidth: 'none' }}
                    type="number"
                    min={1}
                    max={50}
                    {...register('markPercentage')}
                  />
                </div>
              </>
            }
            {
              settings.elasticTimer &&
              <>
                <label className='label' style={{color: "#fff"}} htmlFor="markPercentage" >Cronometro</label>
                <div style={{ width: '100%', margin: '0 auto', marginBottom: 15 }}>
                  <input
                    id="referralPercentage"
                    className='cnt-input' 
                    style={{ width: '100%', maxWidth: 'none' }}
                    type="number"
                    min={15}
                    max={900}
                    {...register('timer')}
                  />
                </div>
              </>  
            }
            {
              settings.elasticBets &&
              <>
                <label className='label' style={{color: "#fff"}} htmlFor="markPercentage" >Qnt. de lances</label>
                <div style={{ width: '100%', margin: '0 auto', marginBottom: 15 }}>
                  <input
                    id="referralPercentage"
                    className='cnt-input' 
                    style={{ width: '100%', maxWidth: 'none' }}
                    type="number"
                    min={1}
                    max={10}
                    {...register('betsUse')}
                  />
                </div>
              </>
            }
            
            <label className='label' style={{color: "#fff"}} htmlFor="maxPrice" >Preço maxímo</label>
            <div style={{ width: '100%', margin: '0 auto', marginBottom: 15 }}>
              <IntlCurrencyInput
								type='text'
								id='maxPrice'
								name='maxPrice'
								config={currencyConfig}
								currency='BRL'
								value={parseFloat(String(getValues()?.maxPrice || 0))}
								style={{ color: '#000', maxWidth: "100%", width: "100%" }}
								onChange={(e: any, val: any) => {
									setValue("maxPrice", parseFloat(val));
								}}
								className='cnt-input wd-100'
								required
							/>
            </div>
              
            <label className='label' style={{color: "#fff"}} htmlFor="pin" >Senha</label>
            <div style={{ width: '100%', margin: '0 auto', marginBottom: 15 }}>
              <input
                type="password"
                id="pin"
                className='cnt-input' 
                style={{ width: '100%', maxWidth: 'none' }}
                {...register('pin')}
                required
              />
            </div>
            
            <label className="label" style={{color: "#fff"}}>2FA</label>
            <div style={{ width: '100%', margin: '0 auto' }}>
              <input
                className='cnt-input' 
                style={{ width: '100%', maxWidth: 'none' }}
                type="text"
                {...register('otpAuth')}
                id="otpAuth"
                required
              />
              
            </div>

            {
              loading &&
              <svg xmlns="http://www.w3.org/2000/svg" style={{margin: '0 auto', display: 'block', shapeRendering: 'auto'}} width="100px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <path fill="none" stroke="#ffffff" strokeWidth="8" strokeDasharray="42.76482137044271 42.76482137044271" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" strokeLinecap="round" style={{transform: 'scale(0.8)', transformOrigin: '50px 50px'}}>
                  <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"></animate>
                </path>
              </svg>
            }
            {
              !loading &&
              <div className="buttons-c">
                <input type="submit" disabled={loading} value={auction?.id ? "EDITAR" : "CRIAR"} className="submit" />
                {/* <input type="reset" value="CANCELAR" className="reset" /> */}
              </div>
            }
          </div>
        </form>
      </Modal>
  )
}