import axios from "axios";

let isRefreshing = false;
let failedRequestsQueue: Array<any> = [];

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.request.use((config: any) => {
  if (sessionStorage.getItem('gear-io-id')) {
    config.headers['gear-io-id'] = sessionStorage.getItem('gear-io-id');
  }
  if (sessionStorage.getItem('gear-io-csrf')) {
    config.headers['gear-io-csrf'] = sessionStorage.getItem('gear-io-csrf');
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  if (response.headers) {
    sessionStorage.setItem('gear-io-csrf', response.headers['gear-io-csrf']);
  }
  if (response.data.user) {
    const user = response.data.user;
    if (user === Object(user))  {
      sessionStorage.setItem('user', JSON.stringify(response.data.user));
    }
  }
  return response;
}, (error) => {
  if (error?.response?.headers) {
    sessionStorage.setItem('gear-io-csrf', error?.response?.headers['gear-io-csrf']);
  }
  if ((error?.response?.data?.errorCode || '').match(/^CSRF/g)) {
    const originalConfig = error.config;
    sessionStorage.removeItem('user');

    // if (!isRefreshing) {
    //   isRefreshing = true;
    //   api.get('/session').then((response) => {
    //     sessionStorage.setItem('gear-io-id', response.data.id);
    //     sessionStorage.setItem('gear-io-csrf', response.data.csrf);

    //     failedRequestsQueue.forEach((request: any) => request.success({
    //       id: sessionStorage.getItem('gear-io-id'),
    //       csrf: sessionStorage.getItem('gear-io-csrf')
    //     }))
    //     failedRequestsQueue = [];
    //   }).catch((err) => {
    //     failedRequestsQueue.forEach((request) => request.failure(err));
    //     failedRequestsQueue = [];
    //   }).finally(() => {
    //     isRefreshing = false;
    //   })
    // }

    return new Promise((resolve, reject) => {
      failedRequestsQueue.push({
        success: (session: any) => {
          originalConfig.headers['gear-io-id'] = session.id;
          originalConfig.headers['gear-io-csrf'] = session.csrf;

          resolve(api(originalConfig));
        },
        failure: (err: any) => {
          reject(err);
        },
      });
    });
  } /*else if (error?.response?.status !== 404 && error?.response?.status !== 500) {
    return Promise.reject(error);
  }*/
  return Promise.reject(error);
})