/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import chevronSVG from '../../assets/img/icons/chevron-select.svg';
import { api } from '../../services/api';
import dayjs from 'dayjs';


export function CashFlowDaily() {

  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [page, setPage] = useState(1)
  const [disableSeeMore, setDisableSeeMore] = useState(false)
  const [flows, setFlows] = useState<any>([])

  async function searchFlows(reset?: boolean) {
    if (reset == true) {
      setPage(1)
      setFlows([])
    }
    try {
      const response = await api.post("/manager/reports/cash-flow-daily", {page: reset == true ? 1 : page, start, end})
      if (page == 1 || reset == true) {
        setFlows(response.data)        
      } else if (page > 1 && response.data.length > 0) {
        setFlows([...flows, ...response.data])
      } 
      if (response.data.length < 25) {
        setDisableSeeMore(true)
      }
      setFlows(response.data)        
    } catch (err: any) {
      toast(`${err?.response?.data?.message}`, {
        style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
    }
  }

  useEffect(() => {
    searchFlows()
  }, [page])
  
  useEffect(() => {
    searchFlows(true)
  }, [start, end])

  return(
    <div className="content-c active" id="content-2">
      <div className="content">
        <h1>Fluxo de caixa por data</h1>
        <div className="filters">
          {/* <select name="options" className="select" onChange={(e) => setStatus((e.target.value))}>
            <option value=''>Todos</option>
            <option value='w'>Abertos</option>
            <option value='r'>Respondidos</option>
            <option value='c'>Fechados</option>
            
          </select> */}
          <form onSubmit={(e) => {
            e.preventDefault();
            }}>
            <input 
              id="start" 
              type="date" 
              className="search" 
              placeholder="Data"
              title="Data de início"
              onChange={(e) => setStart(e.target.value)}
            />
            <input 
              id="end" 
              type="date" 
              className="search" 
              placeholder="Data"
              title="Data de fim"
              onChange={(e) => setEnd(e.target.value)}
            />
            {/* <div className="buttons-c">
              <a onClick={() => setSearch(valueSearch)} className="button">
                <svg className="img" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" >
                  <path fill="var(--white-2)" d="M37.9,38c-1.4,1.4-3.7,1.4-5,0l0,0l-6.3-6.3c-7.7,5-18,2.7-23-5S0.9,8.6,8.6,3.6s18-2.7,23,5 c3.6,5.5,3.6,12.5,0,18l6.3,6.3C39.4,34.3,39.4,36.6,37.9,38L37.9,38z M17.7,5.9c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8 s11.8-5.3,11.8-11.8S24.3,5.9,17.7,5.9L17.7,5.9z"/>
                </svg>
              </a>
              <a onClick={() => console.log()} className="button">
                
                <svg className="img" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" >
                  <path fill="var(--white-2)" d="M5.6,17c1.2,0,2.3-0.7,2.8-1.8c2-4.9,6.8-8,11.9-8c2.8,0,5.7,0.9,7.9,2.7l-1.7,0.3c-0.7,0.1-1.2,0.8-1.1,1.5 c0.1,0.4,0.4,0.8,0.8,1l9.4,4.2c0.7,0.3,1.4,0,1.7-0.7c0.1-0.2,0.1-0.3,0.1-0.5l0.1-10.3c0-0.7-0.6-1.3-1.3-1.3 c-0.4,0-0.9,0.2-1.1,0.6l-1.3,1.9c-7.5-7.6-19.6-7.5-27,0c-1.7,1.7-3,3.7-4,6c-0.7,1.6,0.1,3.3,1.6,4C4.8,16.9,5.2,17,5.6,17 L5.6,17z" />
                  <path fill="var(--white-2)" d="M36.9,24.6c-0.9-1.4-2.8-1.8-4.2-0.9c-0.5,0.3-0.9,0.8-1.1,1.3c-2,4.9-6.8,8-11.9,8c-2.8,0-5.7-0.9-7.9-2.7 l1.7-0.3c0.7-0.1,1.2-0.8,1.1-1.5c-0.1-0.4-0.4-0.8-0.8-1l-9.4-4.2c-0.7-0.3-1.4,0-1.7,0.7c-0.1,0.2-0.1,0.3-0.1,0.5v10.3 c0,0.7,0.6,1.3,1.3,1.3c0.4,0,0.9-0.2,1.1-0.6l1.3-1.9c7.5,7.4,19.6,7.3,27-0.2c1.7-1.7,3-3.7,4-6C37.6,26.6,37.5,25.4,36.9,24.6z" />
                </svg>
              </a>
            </div> */}
            
          </form>
        </div>
        <div className="table-c">
          <table className="table">
            <thead>
              <tr>
                <th>Data</th>
                <th>Depósitos em crypto</th>
                <th>Depósitos em pix</th>
                <th>Taxas de saque</th>
                <th>Apostas</th>
                <th>Estorno taxas de saque</th>
                <th>Pagamento de apostas</th>
                <th>Pagamento de cashback</th>
                <th>Pagamento de cupons</th>
                <th className="align-right">Pagamento de bonus</th>
              </tr>
            </thead>
            <tbody>
              {
                flows && flows.map((flow: any) => (
                  <tr>
                    <td>{flow?.date ? dayjs(flow?.date).format('DD-MM-YYYY'): ""}</td>
                    <td>{flow?.depositCryptoFormat}</td>
                    <td>{flow?.depositPixFormat}</td>
                    <td>{flow?.withdrawFeeFormat}</td>
                    <td>{flow?.betFormat}</td>
                    <td>{flow?.reverseWithdrawFeeFormat}</td>
                    <td>{flow?.payBetFormat}</td>
                    <td>{flow?.payCashbackFormat}</td>
                    <td>{flow?.payCouponFormat}</td>
                    <td>{flow?.payBonusFormat}</td>
                  </tr>
                ))
              }
              
            </tbody>
          </table>
        </div>
        {
          !disableSeeMore &&
          <div className='table-c'>
            <div className="loader-c">
              <a onClick={() => setPage(page + 1)} className="loader">
                <img src={chevronSVG} className="icon" />
                Ver mais
              </a>
            </div>
          </div>
        }
      </div>   
    </div>
  )
}