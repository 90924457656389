/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/AuthContext';
import { api } from '../../services/api';
// import IntlCurrencyInput from 'react-intl-currency-input';

import chevronSVG from '../../assets/img/icons/chevron-select.svg';
import { useForm } from 'react-hook-form';
import { /* FiDollarSign,  */ FiEdit3 /* , FiSlash */ } from 'react-icons/fi';
// import { Modal } from '../../components/Modal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { UpdateWinnerPrizeModal } from '../../components/Modal/UpdateWinnerPrize';
dayjs.extend(utc);

const statusList: any = {
	w: 'Aguardando',
	p: 'Pago',
	s: 'Enviado',
	x: 'Cancelado',
};

const prizeTypeList: any = {
	m: 'Em dinheiro',
	p: 'Receber produto',
	x: 'Não selecionado',
};

export function Winners() {
	const { toggleLoading, loading } = useContext(AuthContext);

	const [auctions, setAuctions] = useState<Array<any>>([]);
	const [page, setPage] = useState(1);
	const [disablePagination, setDisablePagination] = useState(true);
	const [winnerPrizeSelected, setWinnerPrizeSelected] = useState<any>();

	const [modal, setModal] = useState(false);

	const { register, getValues } = useForm<any>();
	// const { register: registerEdit, reset: resetEdit, getValues: getValuesEdit } = useForm<any>();

	async function searchWinnersPrize() {
		toggleLoading(true);
		try {
			const response = await api.post('/manager/auction/winners', { ...getValues(), page: page });
			if (page === 1) {
				setDisablePagination(false);
				setAuctions(response.data);
			} else if (page > 1 && response.data.length > 0) {
				setDisablePagination(false);
				setAuctions([...auctions, ...response.data]);
			}
			if (response.data.length < 25) {
				setDisablePagination(true);
			}
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		toggleLoading(false);
	}

	useEffect(() => {
		searchWinnersPrize();
	}, [page]);

	useEffect(() => {
		if (winnerPrizeSelected) {
			setModal(true);
		}
	}, [winnerPrizeSelected]);

	return (
		<>
			<div className='content-c active' id='content-2'>
				<div className='content'>
					<h1>Leilões</h1>
					<div className='filters'>
						<select className='select' {...register('statusBilling')} title='Status de pagamento'>
							<option value=''>Todos</option>
							<option value='p'>Pagos</option>
							<option value='w'>Aguardando</option>
							<option value='x'>Cancelados</option>
						</select>
						<select className='select' {...register('statusShipping')} title='Status de envio'>
							<option value=''>Todos</option>
							<option value='s'>Enviados</option>
							<option value='w'>Aguardando</option>
							<option value='x'>Cancelados</option>
						</select>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								if (page === 1) {
									searchWinnersPrize();
								} else {
									setPage(1);
								}
							}}
						>
							<input id='search' type='text' className='search' placeholder='Search' {...register('search')} />
							<div className='buttons-c'>
								<button type='submit' className='button'>
									<svg className='img' version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 40 40'>
										<path
											fill='var(--white-2)'
											d='M37.9,38c-1.4,1.4-3.7,1.4-5,0l0,0l-6.3-6.3c-7.7,5-18,2.7-23-5S0.9,8.6,8.6,3.6s18-2.7,23,5 c3.6,5.5,3.6,12.5,0,18l6.3,6.3C39.4,34.3,39.4,36.6,37.9,38L37.9,38z M17.7,5.9c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8 s11.8-5.3,11.8-11.8S24.3,5.9,17.7,5.9L17.7,5.9z'
										/>
									</svg>
								</button>
							</div>
						</form>
					</div>
					<div className='table-c'>
						<table className='table'>
							<thead>
								<tr>
									<th>Leilão</th>
									<th>Produto</th>
									<th>Nome</th>
									<th>Valor do produto</th>
									<th>Valor do arremate</th>
									<th>Valor máximo leilão</th>
									<th>Usuário</th>
									<th>Id-Usuário</th>
									<th>Documento</th>
									<th>E-mail</th>
									<th>Tipo do premio</th>
									<th>Pagamento</th>
									<th>Envio</th>
									<th className='align-right'>Menu</th>
								</tr>
							</thead>
							<tbody>
								{auctions &&
									auctions.map((auction) => (
										<tr key={auction?.auction}>
											<td>{auction?.auction}</td>
											<td>{auction?.auctionData?.product}</td>
											<td>{String(auction?.auctionData?.productData?.name).substring(0, 25)}</td>
											<td>
												{new Intl.NumberFormat('pt-br', {
													style: 'currency',
													currency: 'BRL',
												}).format(auction?.auctionData?.productData?.price ? Number(auction?.auctionData?.productData?.price) : 0)}
											</td>
											<td>
												{new Intl.NumberFormat('pt-br', {
													style: 'currency',
													currency: 'BRL',
												}).format(auction?.order ? Number(auction?.order) / 100 : 0)}
											</td>
											<td>
												{new Intl.NumberFormat('pt-br', {
													style: 'currency',
													currency: 'BRL',
												}).format(auction?.auctionData?.maxPrice ? Number(auction?.auctionData?.maxPrice) : 0)}
											</td>
											<td>{auction?.auctionData?.userWinnerData?.userName}</td>
											<td>{auction?.auctionData?.userWinner}</td>
											<td>{auction?.auctionData?.userWinnerData?.document}</td>
											<td>{auction?.auctionData?.userWinnerData?.email}</td>
											<td>{prizeTypeList[auction?.prizeType || 'x']}</td>
											<td>{statusList[auction?.statusBilling]}</td>
											<td>{statusList[auction?.statusShipping]}</td>
											<td className='align-right'>
												<a title='Editar arremate' onClick={() => setWinnerPrizeSelected(auction)} className='mini-button'>
													<FiEdit3 />
												</a>
											</td>
										</tr>
									))}
							</tbody>
						</table>
						<div className='loader-c'>
							<button disabled={disablePagination || loading} onClick={() => setPage(page + 1)} className={`loader ${disablePagination == true ? 'hidden' : ''}`}>
								<img src={chevronSVG} className='icon' />
								Carregar Mais
							</button>
						</div>
					</div>
				</div>
			</div>
			<UpdateWinnerPrizeModal
				handler={modal}
				onRequestClose={() => {
					setModal(false);
					searchWinnersPrize();
				}}
				winnerPrize={winnerPrizeSelected}
			/>
		</>
	);
}
