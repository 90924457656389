/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useState } from "react";
import { Header } from "../../components/header";
import { BetsUser } from "./BetsUser";
import { BlackList } from "./BlackList";
// import { CashUser } from "./CashUser";
import { UserInfo } from "./UserInfo";
import { Users } from "./Users";

export function Home() {
  const [content, setContent] = useState("content-1")
  const [selectedUser, setSelectedUser] = useState<any>({})
  return(
    <>
      <Header />
      
      <div className="location">
        <span className="page">Início » </span>O resumo da sua conta
      </div>

      <div className="tab-c">
        <a onClick={() => setContent('content-1')} className={`tab ${content === "content-1" ? "active": ""}`}>Usuários</a>
        {/* <a onClick={() => setContent('content-2')} className={`tab ${content === "content-2" ? "active": ""}`}>Transações</a> */}
        <a onClick={() => setContent('content-5')} className={`tab ${content === "content-5" ? "active": ""}`}>Lances</a>
        <a onClick={() => setContent('content-3')} className={`tab ${content === "content-3" ? "active": ""}`}>Editar</a>
        <a onClick={() => setContent('content-4')} className={`tab ${content === "content-4" ? "active": ""}`}>BlackList</a>
        {/* <a onClick={() => setContent('content-4')} className={`tab ${content === "content-4" ? "active": ""}`}>QR Code</a>
        <a onClick={() => setContent('content-5')} className={`tab ${content === "content-5" ? "active": ""}`}>AcquaCred</a>
        <a onClick={() => setContent('content-10')} className={`tab ${content === "content-10" ? "active": ""}`}>Form/Table</a> */}
      </div>

      {content === "content-1" && <Users selectedUser={setSelectedUser} selectTab={setContent} />}
      {/* {content === "content-2" && <CashUser user={selectedUser} />} */}
      {content === "content-3" && <UserInfo user={selectedUser} />}
      {content === "content-4" && <BlackList />}
      {content === "content-5" && <BetsUser user={selectedUser} />}
      {/* {content === "content-5" && <Dashboard />} */}
      {/* {content === "content-10" && <FormTable />} */}
    </>
  )
}