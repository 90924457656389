/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, ReactNode, useEffect } from 'react'
import { toast } from 'react-toastify'
import { string } from 'yup'
import { IAdminDTO } from '../dtos/IAdminDTO'
import { api } from '../services/api'


interface AuthProviderProps {
  children: ReactNode
}

interface AuthContextData {
  admin: IAdminDTO;
  SignIn: Function;
  logout: Function;
  loading: boolean;
  toggleLoading: Function;
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {
  const sessionItem = "gear-io";
  const [loading, toggleLoading] = useState(false)
  const [admin, setAdmin] = useState<IAdminDTO>({} as IAdminDTO);

  async function SignIn(login: string, password: string) {
    try {
      const response = await api.post("/manager/sign-in", {login, password});
      sessionStorage.setItem(sessionItem, JSON.stringify(response.data))
      sessionStorage.setItem("gear-io-id", response.data.id)
      sessionStorage.setItem("gear-io-csrf", response.data.csrf)
      setAdmin(response.data.user)
    } catch (err: any) {
      throw new Error(String(err?.response?.data?.message));
    }
  }

  async function logout() {
    sessionStorage.removeItem(sessionItem)
    sessionStorage.removeItem("gear-io-id")
    sessionStorage.removeItem("gear-io-csrf")
    setAdmin({} as IAdminDTO)
  }

  useEffect(() => {
    const id = sessionStorage.getItem("gear-io-id")
    const csrf = sessionStorage.getItem("gear-io-csrf")
    const getSession = sessionStorage.getItem(sessionItem);
    if (getSession && id && csrf) {
      setAdmin(JSON.parse(getSession).user)
    } else {
      setAdmin({} as IAdminDTO);
    }
  }, [])

  return (
    <AuthContext.Provider value={{
      admin,
      SignIn,
      logout,
      loading,
      toggleLoading,
    }}>
      {children}
    </AuthContext.Provider>
  )
}
