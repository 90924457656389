/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useEffect, useState } from "react";
import { Header } from "../../components/header";
import { Auctions } from "./Auctions";
import { BetsAuction } from "./BetsAuction";
import { PlanInfo } from "./PlanInfo";
import { Plans } from "./Plans";
import { ProductInfo } from "./ProductInfo";
import { Products } from "./Products";
import { Winners } from "./Winners";


export function Auction() {
  const [content, setContent] = useState("content-1")
  const [auction, setAuction] = useState<any>()
  const [product, setProduct] = useState<any>()
  const [plan, setPlan] = useState<any>()
  
  function handleChangeTab(ac: any, tab: string) {
    setAuction(ac)
    setContent(tab)
  }
  
  useEffect(() => {
    if (product) {
      setContent('content-5')
    }
  }, [product])
  useEffect(() => {
    if (plan) {
      setContent('content-7')
    }
  }, [plan])

  return(
    <>
      <Header />
      
      <div className="location">
        <span className="page">Leilões » </span>
      </div>

      <div className="tab-c">
        <a onClick={() => setContent('content-1')} className={`tab ${content === "content-1" ? "active": ""}`}>Leilões</a>
        <a onClick={() => setContent('content-3')} className={`tab ${content === "content-3" ? "active": ""}`}>Lances do Leilão</a>
        <a onClick={() => setContent('content-2')} className={`tab ${content === "content-2" ? "active": ""}`}>Ganhadores</a>
        <a onClick={() => setContent('content-4')} className={`tab ${content === "content-4" ? "active": ""}`}>Produtos</a>
        <a onClick={() => setContent('content-5')} className={`tab ${content === "content-5" ? "active": ""}`}>{!product?.id ? "Criar" : "Editar"} Produto</a>
        <a onClick={() => setContent('content-8')} className={`tab ${content === "content-8" ? "active": ""}`}>Planos</a>
        <a onClick={() => setContent('content-7')} className={`tab ${content === "content-7" ? "active": ""}`}>{!plan?.id ? "Criar" : "Editar"} Plano</a>
      </div>

      {content == "content-1" && <Auctions select={handleChangeTab} />}
      {content == "content-2" && <Winners />}
      {content == "content-3" && <BetsAuction auction={auction} />}
      {content == "content-4" && <Products select={setProduct} />}
      {content == "content-5" && <ProductInfo product={product} updateProduct={setProduct} />}
      {content == "content-6" && <ProductInfo product={product} updateProduct={setProduct} />}
      {content == "content-7" && <PlanInfo product={plan} updateProduct={setPlan} />}
      {content == "content-8" && <Plans select={setPlan} />}
    
    </>
  )
}