import styled from 'styled-components'

interface ModalProps {
  opacity?: number;
  noPadding?: boolean;
  width?: number;
}

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  inset: 0;
  background-color: ${(props: ModalProps) => props.opacity ? `rgba(0,0,0,${props.opacity})` : 'rgba(0,0,0,0.8)'};
  display: flex;
  align-items: center;
  justify-content: center;
  
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: var(--all-transition);

  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

export const ModalContent = styled.div`
  width: ${(props: ModalProps) => props.width ? `${props.width}vw` : `auto`};
  max-width: 800px;
  max-height: 90vh;
  background-color: var(--blue-4);
  overflow: auto;
  padding: ${(props: ModalProps) => props.noPadding ? `0` : `2rem`};
  border-radius: 8px;

  input {
    width: 100%;
    height: 35px;
    border-radius: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    display: inline-block;
    border: 0;
    border-radius: 8px;
    border-top-left-radius: 3px;
    outline: 0;
    padding: 10px 20px;
    color: var(--white);
    font-size: 15px;
    font-weight: 300;
    text-transform: none;
    /* cursor: pointer;
    transition: 0.3s; */
  }

  & .react-select__container {
    & .react-select__control {
      background-color: var(--white);
      min-height: inherit;

      & .react-select__single-value {
        /* color: var(--white); */
      }

      & .react-select__input-container {
        color: var(--black);

        & .react-select__input {
          height: auto;
        }
      }
    }

    & .react-select__menu {
      background-color: var(--white);

      & .react-select__option {
        &.react-select__option--is-focused {
          /* background-color: var(--blue-1); */
        }
        &.react-select__option--is-selected {
          background-color: var(--blue-4);
        }
      }
    }
  }

  textarea {
    width: 100%;
    /* height: 35px; */
    border-radius: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    display: inline-block;
    border: 0;
    border-radius: 8px;
    border-top-left-radius: 3px;
    outline: 0;
    padding: 10px 20px;
    color: var(--white);
    font-size: 15px;
    font-weight: 300;
  }
`

export const CloseModal = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  color: #fff;
  cursor: pointer;
`