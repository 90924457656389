/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import chevronSVG from '../../assets/img/icons/chevron-select.svg';
import { UsersTable } from '../../components/tables/UsersTable';
import { api } from '../../services/api';

interface IProps {
  selectedUser: Function;
  selectTab: Function;
}

export function Users({selectedUser, selectTab}: IProps) {
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('')
  const [valueSearch, setValueSearch] = useState('')
  const [disableSeeMore, setDisableSeeMore] = useState(false)

  async function searchUsers(reset?: boolean) {
    if (reset) {
      setPage(1)
      setDisableSeeMore(false)
    }
    try {
      const response = await api.post("/manager/users/search", {page: reset ? 1 : page, search, status});
      if (response.data.length < 1) {
        setDisableSeeMore(true)
        if (reset || page === 1) {
          setUsers([])
        }
        return
      } else if (response.data && (reset || page === 1)) {
        setUsers(response.data)
      } else if (response.data.length > 0) {
        const newUsers: [] = response.data
        setUsers([...users, ...newUsers])
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (page > 1) {
      searchUsers()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    searchUsers(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, status])
  

  return(
    <>
    <div className="content-c active" id="content-2">
			
			<div className="content">
				<h1>Contas cadastradas</h1>
				<div className="filters">
					<select name="options" className="select" onChange={(e) => setStatus(e.target.value)}>
						<option value=''>Todos</option>
						<option value='a'>Ativos</option>
						<option value='i'>Inativos</option>
            <option value='p'>Pendente</option>
					</select>
          <form onSubmit={(e) => {
            e.preventDefault();
            setSearch(valueSearch)
            }}>
            <input 
              id="search" 
              type="text" 
              className="search" 
              placeholder="Search"
              onChange={(e) => setValueSearch(e.target.value)}
            />
            <div className="buttons-c">
              <a onClick={() => setSearch(valueSearch)} className="button">
                <svg className="img" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" >
                  <path fill="var(--white-2)" d="M37.9,38c-1.4,1.4-3.7,1.4-5,0l0,0l-6.3-6.3c-7.7,5-18,2.7-23-5S0.9,8.6,8.6,3.6s18-2.7,23,5 c3.6,5.5,3.6,12.5,0,18l6.3,6.3C39.4,34.3,39.4,36.6,37.9,38L37.9,38z M17.7,5.9c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8 s11.8-5.3,11.8-11.8S24.3,5.9,17.7,5.9L17.7,5.9z"/>
                </svg>
              </a>
            </div>
            
          </form>
				</div>
        <UsersTable selectTab={selectTab} selectedUser={selectedUser} users={users} />
        {
          !disableSeeMore &&
          <div className='table-c'>
            <div className="loader-c">
              <a onClick={() => setPage(page + 1)} className="loader">
                <img src={chevronSVG} className="icon" />
                Ver mais
              </a>
            </div>
          </div>
        }
      </div>
		
      </div>
      </>
  )
}