import { Switch, Route } from 'react-router-dom';
import { Auction } from '../pages/Auction';
// import { Bets } from '../pages/Bets'
import { Financial } from '../pages/Financial';
import { Home } from '../pages/Home';
import { Platform } from '../pages/Platform';
import { Profile } from '../pages/Profile';
import { Reports } from '../pages/Reports';
// import { Support } from '../pages/Support'

export function AuthRoutes() {
	return (
		<Switch>
			<Route path='/home' component={Home} />
			<Route path='/financial' component={Financial} />
			<Route path='/auction' component={Auction} />
			<Route path='/profile' component={Profile} />
			<Route path='/platform' component={Platform} />
			<Route path='/reports' component={Reports} />
		</Switch>
	);
}
