import { ReactNode, useEffect, useRef } from 'react'
import { FiX } from 'react-icons/fi'

import { ModalOverlay, ModalContent, CloseModal } from './styles'

interface ModalProps {
  handler: boolean;
  children: ReactNode;
  overlayOpacity?: number;
  onRequestClose?: () => void;
  noPadding?: boolean;
  noClose?: boolean;
  width?: number;
}

export function Modal({ 
  children, 
  handler, 
  overlayOpacity, 
  onRequestClose, 
  noPadding, 
  noClose = false, 
  width 
}: ModalProps) {
  const keyRef = useRef<HTMLDivElement>(null)
  
  useEffect(() => {
    let handleKey = (event: any) => {
      if (null != keyRef.current) {
        if (event.key === 'Escape') {
          if (onRequestClose) {
            onRequestClose()
          }
        }
      }
    }
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey);
    }
  })

  return (
    <ModalOverlay
      className={`${handler ? 'active' : ''}`}
      opacity={overlayOpacity}
      ref={keyRef}
    >
      <ModalContent width={width} noPadding={noPadding}>
        {children}
      </ModalContent>
      {!noClose && (
        <CloseModal onClick={onRequestClose}>
          <FiX />
        </CloseModal>
      )}
    </ModalOverlay>
  )
}