/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useEffect, useState } from "react"
import { Header } from "../../components/header"
import { CashFlow } from "./CashFlow"
import { CashFlowDaily } from "./CashFlowDaily"
import { CashFlowUser } from "./CashFlowUser"
import { CashFlowUserDaily } from "./CashFlowUserDaily"
import { CashSumByLeague } from "./CashSumByLeague"
import { CashSumByMatch } from "./CashSumByMatch"
import { CashSumBySport } from "./CashSumBySport"
import { CashSumByType } from "./CashSumByType"


export function Reports() {
  const [content, setContent] = useState("content-0")
  const [ticket, setTicket] = useState<any>({})

  function changeTab(tic: any) {
    setTicket(tic)
    setContent("content-2")
  }

  return(
    <>
      <Header />
      
      <div className="location">
        <span className="page">Relatórios » </span>
      </div>

      <div className="tab-c">
        <a onClick={() => setContent('content-0')} className={`tab ${content === "content-0" ? "active": ""}`}>Caixa</a>
        <a onClick={() => setContent('content-01')} className={`tab ${content === "content-01" ? "active": ""}`}>Caixa p/ data</a>
        {/* <a onClick={() => setContent('content-1')} className={`tab ${content === "content-1" ? "active": ""}`}>Esportes</a>
        <a onClick={() => setContent('content-2')} className={`tab ${content === "content-2" ? "active": ""}`}>Partidas</a>
        <a onClick={() => setContent('content-3')} className={`tab ${content === "content-3" ? "active": ""}`}>Ligas</a>
        <a onClick={() => setContent('content-4')} className={`tab ${content === "content-4" ? "active": ""}`}>Tipo de aposta</a> */}
        <a onClick={() => setContent('content-5')} className={`tab ${content === "content-5" ? "active": ""}`}>Fluxo p/ usuário</a>
        <a onClick={() => setContent('content-6')} className={`tab ${content === "content-6" ? "active": ""}`}>Fluxo de usuário p/ data</a>
      </div>

      {
        content === "content-0" && 
        <CashFlow />
      }
      {
        content === "content-01" && 
        <CashFlowDaily />
      }
      {/* {
        content === "content-1" && 
        <CashSumBySport />
      }
      {
        content === "content-2" && 
        <CashSumByMatch />
      }
      {
        content === "content-3" && 
        <CashSumByLeague />
      }
      {
        content === "content-4" && 
        <CashSumByType />
      } */}
      {
        content === "content-5" && 
        <CashFlowUser />
      }
      {
        content === "content-6" && 
        <CashFlowUserDaily />
      }

    </>
  )
}