/* eslint-disable max-len */
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/AuthContext';
import { api } from '../../services/api';

export function UpdatePassword() {
	const { toggleLoading } = useContext(AuthContext);

	const { register, getValues, reset } = useForm<any>({
		mode: 'onSubmit',
		reValidateMode: 'onBlur',
	});

	async function handleUpdatePassword() {
		toggleLoading(true);
		const data = getValues();
		if (data.confirmPassword !== data.newPassword) {
			toast(`Senhas divergentes`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			toggleLoading(false);
			return;
		}
		try {
			await api.post('/password/manager/update-password', data);
			toast(`Senha alterada com sucesso!`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			reset();
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		toggleLoading(false);
	}

	return (
		<div className='content-c active' id='content-1'>
			<div className='content'>
				<h1>Alterar senha</h1>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleUpdatePassword();
					}}
					className='form'
					acceptCharset='utf-8'
					method='post'
				>
					<div className='input-c'>
						<input type='password' title='Senha antiga' className='input wd-33' placeholder='Senha antiga' {...register('oldPassword')} required />
					</div>
					<div className='input-c'>
						<input type='password' title='Nova senha' className='input wd-33' placeholder='Nova senha' {...register('newPassword')} minLength={8} required />
					</div>
					<div className='input-c'>
						<input type='password' title='Confirmar senha' className='input wd-33' placeholder='Confirmar senha' {...register('confirmPassword')} minLength={8} required />
					</div>
					<div className='buttons-c'>
						<input type='submit' value='ATUALIZAR' className='submit' />
					</div>
				</form>
			</div>
		</div>
	);
}
