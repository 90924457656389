/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useState } from "react";
import { Header } from "../../components/header";
import { UpdatePassword } from "./UpdatePassword";


export function Profile() {
  const [content, setContent] = useState("content-1")

  return(
    <>
      <Header />
      
      <div className="location">
        <span className="page">Perfil » </span>
      </div>

      <div className="tab-c">
        <a onClick={() => setContent('content-1')} className={`tab ${content === "content-1" ? "active": ""}`}>Atualizar senha</a>
      </div>

      {
        content === "content-1" && 
        <UpdatePassword />
      }

    </>
  )
}