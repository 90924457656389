/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiEdit3, FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';
import chevronSVG from '../../assets/img/icons/chevron-select.svg';
import { Modal } from '../../components/Modal';
import { AuthContext } from '../../contexts/AuthContext';
import { api } from '../../services/api';

interface IProps {
	select: Function;
}

export function Testimony({ select }: IProps) {
	const { toggleLoading, loading } = useContext(AuthContext);

	const [contacts, setContacts] = useState<any[]>([]);
	const [page, setPage] = useState(1);
	const [disableSeeMore, setDisableSeeMore] = useState(false);
	const [modal, setModal] = useState(false);

	const { register, getValues, setValue, reset } = useForm<any>({
		mode: 'onSubmit',
		reValidateMode: 'onBlur',
	});

	async function searchUsers(reset?: boolean) {
		if (reset) {
			setPage(1);
			setDisableSeeMore(false);
		}
		try {
			const response = await api.get('/manager/testimony', { params: { page } });
			if (response.data.length < 1) {
				setDisableSeeMore(true);
				if (reset || page === 1) {
					setContacts([]);
				}
				return;
			} else if (response.data && (reset || page === 1)) {
				setContacts(response.data);
			} else if (response.data.length > 0) {
				const newUsers: [] = response.data;
				setContacts([...contacts, ...newUsers]);
			}
		} catch (err) {}
	}

	async function handleDeleteTestimony(data: any) {
		toggleLoading(true);
		try {
			await api.delete('/manager/testimony/delete', { data });
			toast(`Depoimento deletado com sucesso com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			setModal(false);
			reset();
			searchUsers(true)
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		toggleLoading(false);
	}

	useEffect(() => {
		if (page > 1) {
			searchUsers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		searchUsers(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='content-c active' id='content-2'>
				<div className='content'>
					<h1>Banners</h1>
					<div className='table-c'>
						<table className='table'>
							<thead>
								<tr>
									<th>Imagem</th>
									<th>Id</th>
									<th>Titulo</th>
									<th>Estrelas</th>
									<th>Video</th>
									<th className='align-right'>Menu</th>
								</tr>
							</thead>
							<tbody>
								{contacts &&
									contacts.map((testimony) => (
										<tr key={testimony?.id}>
											<td>
												<img src={testimony?.image} style={{ maxWidth: 100, maxHeight: 100 }} />
											</td>
											<td>{testimony?.id}</td>
											<td>{testimony?.stars}</td>
											<td>{String(testimony?.title).substring(0, 30)}</td>
											<td onClick={() => window.open(testimony?.video)}>
												<a href='#'>{String(testimony?.video).substring(0, 30)}</a>
											</td>
											<td className='align-right'>
												<a title='Editar banner' onClick={() => select({ ...testimony, copy: false, testimonyId: testimony?.id })} className='mini-button'>
													<FiEdit3 />
												</a>
												<a title='Excluir banner' onClick={() => {
														setValue("id", testimony?.id)
														setModal(true)
													}} className='mini-button' style={{background: "#c53030"}}>
													<FiX />
												</a>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
					{!disableSeeMore && (
						<div className='table-c'>
							<div className='loader-c'>
								<a onClick={() => setPage(page + 1)} className='loader'>
									<img src={chevronSVG} className='icon' />
									Ver mais
								</a>
							</div>
						</div>
					)}
				</div>
			</div>
			<Modal
				handler={modal}
				onRequestClose={() => {
					setModal(false);
				}}
			>
				<form
					className='form'
					onSubmit={(e) => {
						e.preventDefault();
						const values = getValues();
						handleDeleteTestimony(values)
					}}
					style={{ alignItems: 'center' }}
				>
					<div>
						<label className='label' style={{ color: '#fff' }} htmlFor='pin'>
							Senha
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<input type='password' id='pin' className='cnt-input' {...register('pin')} />
						</div>
						{/* <label className='label' style={{ color: '#fff' }}>
							2FA
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto' }}>
							<input className='cnt-input' type='text' id='token' {...register('otpAuth')} />
						</div> */}

						{loading && (
							<svg xmlns='http://www.w3.org/2000/svg' style={{ margin: '0 auto', display: 'block', shapeRendering: 'auto' }} width='100px' height='60px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
								<path
									fill='none'
									stroke='#ffffff'
									strokeWidth='8'
									strokeDasharray='42.76482137044271 42.76482137044271'
									d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z'
									strokeLinecap='round'
									style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}
								>
									<animate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0;256.58892822265625'></animate>
								</path>
							</svg>
						)}
						{!loading && (
							<div className='buttons-c'>
								<input style={{background: "#c53030"}} type='submit' disabled={loading} value="DELETAR DEPOIMENTO" className='submit' />
							</div>
						)}
					</div>
				</form>
			</Modal>
		</>
	);
}
