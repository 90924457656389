/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useEffect, useState } from "react";
import { Header } from "../../components/header";
import { Banners } from "./Banners";
import { CreateBanner } from "./CreateBanner";
import { CreateTestimony } from "./CreateTestimony";
import { Testimony } from "./Testimony";
import { WebContacts } from "./WebContacts";

const titles: any = {
  "content-1": "Lista de contatos",
  "content-2": "Lista de banners",
  "content-3": "Banner",
  "content-4": "Lista de depoimentos",
  "content-5": "Depoimento",
}

export function Platform() {
  const [content, setContent] = useState("content-1")

  const [banner, setBanner] = useState<any>()
  const [testimony, setTestimony] = useState<any>()

  useEffect(() => {
    if (banner?.id) {
      setContent('content-3')
    }
  }, [banner])
  useEffect(() => {
    if (testimony?.id) {
      setContent('content-5')
    }
  }, [testimony])
  
  return(
    <>
      <Header />
      
      <div className="location">
        <span className="page">Plataforma » </span> {titles[content]}
      </div>

      <div className="tab-c">
        <a onClick={() => setContent('content-1')} className={`tab ${content === "content-1" ? "active": ""}`}>Contatos</a>
        <a onClick={() => setContent('content-2')} className={`tab ${content === "content-2" ? "active": ""}`}>Lista de banners</a>
        <a onClick={() => setContent('content-3')} className={`tab ${content === "content-3" ? "active": ""}`}>Banner</a>
        <a onClick={() => setContent('content-4')} className={`tab ${content === "content-4" ? "active": ""}`}>Depoimentos</a>
        <a onClick={() => setContent('content-5')} className={`tab ${content === "content-5" ? "active": ""}`}>Criar depoimento</a>
       
      </div>

      {content === "content-1" && <WebContacts />}
      {content === "content-2" && <Banners select={setBanner} />}
      {content === "content-3" && <CreateBanner banner={banner} updateBanner={setBanner} />}
      {content === "content-4" && <Testimony select={setTestimony} />}
      {content === "content-5" && <CreateTestimony testimony={testimony} updateTestimony={setTestimony} />}

    </>
  )
}