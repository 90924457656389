/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { FiCheck, FiX } from 'react-icons/fi';
import { Modal } from '../Modal';
import { AuthContext } from '../../contexts/AuthContext';
import { api } from '../../services/api';
import { toast } from 'react-toastify';

interface IProps {
	deposits: Array<any>;
	callBack: Function;
}

const status: any = {
	w: 'Aguardando',
	x: 'Cancelado',
	p: 'Pago',
};

export function DepositsTable({ deposits, callBack }: IProps) {
	const { toggleLoading } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [pin, setPin] = useState('');
	const [otpAuth, setOtpAuth] = useState('');
	const [modal, setModal] = useState(false);
	const [params, setParams] = useState<any>({});

	async function handleResolveDepositOrder() {
		toggleLoading(true);
		setLoading(true);
		if (!params) {
			toast(`Ordem não definida`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			setLoading(false);
			toggleLoading(false);
			return;
		}
		try {
			await api.post('/manager/deposits/resolve', { ...params, otpAuth, pin });
			setModal(false);
			setParams('');
			setOtpAuth('');
			setPin('');
			toast(`Ordem ${params?.acceptDeposit == true ? 'aprovada' : 'reprovada'} com sucesso!`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			await callBack();
		} catch (err: any) {
			setOtpAuth('');
			setPin('');
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
		toggleLoading(false);
	}

	function handleSetOrder(p: any) {
		setParams(p);
	}

	useEffect(() => {
		if (params?.orderId) {
			setModal(true);
		}
	}, [params]);

	return (
		<div className='table-c'>
			<table className='table'>
				<thead>
					<tr>
						<th>Id</th>
						<th>Id do usuário</th>
						<th>Nome do usuário</th>
						<th>Login</th>
						<th>Tipo</th>
						<th>Valor</th>
						<th>Taxa</th>
						<th>Valor pago</th>
						<th>Data da solicitação</th>
						<th>Data do pagamento</th>
						<th>Data de expiração</th>
						<th>Status</th>
						<th className='align-right'>Menu</th>
					</tr>
				</thead>
				<tbody>
					{deposits &&
						deposits.map((deposit) => (
							<tr key={deposit?.id}>
								<td>{deposit?.id}</td>
								<td>{deposit?.user}</td>
								<td>{deposit?.userData?.name}</td>
								<td>{deposit?.userData?.userName}</td>
								<td>{deposit?.payinMethodData?.name}</td>
								<td>{deposit?.valueFormat}</td>
								<td>{deposit?.feeFormat}</td>
								<td>{deposit?.valuePaidFormat}</td>
								<td>{deposit?.createdAt && dayjs(deposit?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
								<td>{deposit?.datePay && dayjs(deposit?.datePay).format('DD/MM/YYYY HH:mm:ss')}</td>
								<td>{deposit?.dateExp && dayjs(deposit?.dateExp).format('DD/MM/YYYY HH:mm:ss')}</td>
								<td>{status[deposit?.status]}</td>
								<td className='align-right'>
									{(deposit?.status == 'w' || deposit?.status == 'x') && (
										<>
											<a title='Aprovar depósito (baixa manual)' onClick={() => handleSetOrder({ orderId: deposit?.orderId, acceptDeposit: true })} className='mini-button'>
												<FiCheck />
											</a>
										</>
									)}
									{
										deposit?.status == 'w' &&
										<a title='Recusar depósito' onClick={() => handleSetOrder({ orderId: deposit?.orderId, acceptDeposit: false })} className='mini-button'>
											<FiX />
										</a>
									}
								</td>
							</tr>
						))}
				</tbody>
			</table>
			<Modal
				handler={modal}
				onRequestClose={() => {
					setModal(false);
				}}
			>
				<form
					className='form'
					onSubmit={(e) => {
						e.preventDefault();
						handleResolveDepositOrder();
					}}
					style={{ alignItems: 'center' }}
				>
					<div>
						<label className='label' style={{ color: '#fff' }} htmlFor='pin'>
							Senha
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<input type='password' name='pswd_fin' id='pswd_fin' className='cnt-input' onChange={(e: any) => setPin(e.target.value)} value={pin} />
						</div>
						<label className='label' style={{ color: '#fff' }}>
							2FA
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto' }}>
							<input onChange={(e: any) => setOtpAuth(e.target.value)} className='cnt-input' type='text' name='otp' id='token' value={otpAuth} />
						</div>

						{loading && (
							<svg xmlns='http://www.w3.org/2000/svg' style={{ margin: '0 auto', display: 'block', shapeRendering: 'auto' }} width='100px' height='60px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
								<path
									fill='none'
									stroke='#ffffff'
									strokeWidth='8'
									strokeDasharray='42.76482137044271 42.76482137044271'
									d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z'
									strokeLinecap='round'
									style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}
								>
									<animate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0;256.58892822265625'></animate>
								</path>
							</svg>
						)}
						{!loading && (
							<div className='buttons-c'>
								<input type='submit' disabled={loading} value='EXECUTAR' className='submit' />
								{/* <input type="reset" value="CANCELAR" className="reset" /> */}
							</div>
						)}
					</div>
				</form>
			</Modal>
		</div>
	);
}
