/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { FiCheckCircle, FiPlus, FiX } from "react-icons/fi"
import { Modal } from "../../components/Modal"
import { AuthContext } from "../../contexts/AuthContext"
import { api } from "../../services/api"
import chevronSVG from '../../assets/img/icons/chevron-select.svg';
import dayjs from "dayjs";
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import IntlCurrencyInput from "react-intl-currency-input";

const currencyConfig = {
  locale: "pt-br",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      USD: {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      BTC: {
        style: "currency",
        currency: "XBT",
        minimumFractionDigits: 2,
        maximumFractionDigits: 8,
      },
    },
  },
};

const statusName = {
  "a": "ATIVO",
  "i": "INATIVO",
  "e": "EXPIRADO",
  "x": "CANCELADO"
}

export function Coupons() {
  const { toggleLoading, loading } = useContext(AuthContext)

  const [inserts, setInserts] = useState<any>([])
  const [disableSeeMore, setDisableSeeMore] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [valueSearch, setValueSearch] = useState('')
  const [pin, setPin] = useState('')
  const [otpAuth, setOtpAuth] = useState('')
  const [modal, setModal] = useState(false)
  const [modalCreate, setModalCreate] = useState(false)
  const [params, setParams] = useState<any>({})
  const [value, setValue] = useState(0.0)

  const {
    register,
    getValues,
    reset,
  } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });

  async function searchCoupons(reset?: boolean) {
    if (reset) {
      setPage(1)
      setDisableSeeMore(false)
    }
    try {
      const response = await api.post("/manager/coupon/pagination", {page: reset ? 1 : page, search, status})
      if (response.data.length < 1) {
        setDisableSeeMore(true)
        if (reset || page === 1) {
          setInserts([])
        }
        toggleLoading(false)
        return
      } else if (response.data && (reset || page === 1)) {
        setInserts(response.data)
      } else if (response.data.length > 0) {
        const newPayouts: [] = response.data
        setInserts([...inserts, ...newPayouts])
      }
    } catch (err: any) {
      toast(`${err?.response?.data?.message}`, {
        style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
    }
  }

  async function handleToggleCoupon() {
    toggleLoading(true)
    try {
      await api.post("/manager/coupon/update", { ...params, pin, otpAuth })
      toast(`Inserção ${params?.approved == true ? "aprovada" : "recusada"} com sucesso!`, {
        style: { backgroundColor: "#00A300", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
      setModal(false)
      setPin('')
      setOtpAuth('')
      setParams({})
      searchCoupons()
    } catch(err: any) {
      toast(`${err?.response?.data?.message}`, {
        style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
    }
    toggleLoading(false)
  }

  async function handleCreateCoupon() {
    toggleLoading(true)
    try {
      await api.post("/manager/coupon/create", { ...getValues(), pin, otpAuth })
      toast(`Cupom criado com sucesso!`, {
        style: { backgroundColor: "#00A300", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
      setModalCreate(false)
      setPin('')
      setOtpAuth('')
      reset()
      searchCoupons()
    } catch(err: any) {
      toast(`${err?.response?.data?.message}`, {
        style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
    }
    toggleLoading(false)
  }

  useEffect(() => {
    if (page > 1) {
      searchCoupons()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    searchCoupons(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, status])
  
  useEffect(() => {
    if (params?.code) {
      setModal(true)
    }
  }, [params])
  return(
    <div className="content-c active" id="content-1">
    <div className="content">
      <h1>Cupons</h1>
      <div className="filters">
        <select name="options" className="select" onChange={(e) => setStatus(e.target.value)}>
          <option value=''>Todos</option>
          <option value='a'>Ativos</option>
          <option value='i'>Inativos</option>
          <option value='e'>Expirados</option>
          <option value='x'>Cancelados</option>
        </select>
        <form onSubmit={(e) => {
          e.preventDefault();
          setSearch(valueSearch)
          }}>
          <input 
            id="search" 
            type="text" 
            className="search" 
            placeholder="Buscar"
            onChange={(e) => setValueSearch(e.target.value)}
          />
          <div className="buttons-c">
            <a onClick={() => setSearch(valueSearch)} className="button">
              <svg className="img" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" >
                <path fill="var(--white-2)" d="M37.9,38c-1.4,1.4-3.7,1.4-5,0l0,0l-6.3-6.3c-7.7,5-18,2.7-23-5S0.9,8.6,8.6,3.6s18-2.7,23,5 c3.6,5.5,3.6,12.5,0,18l6.3,6.3C39.4,34.3,39.4,36.6,37.9,38L37.9,38z M17.7,5.9c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8 s11.8-5.3,11.8-11.8S24.3,5.9,17.7,5.9L17.7,5.9z"/>
              </svg>
            </a>
            <a onClick={() => searchCoupons()} className="button">
              
              <svg className="img" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" >
                <path fill="var(--white-2)" d="M5.6,17c1.2,0,2.3-0.7,2.8-1.8c2-4.9,6.8-8,11.9-8c2.8,0,5.7,0.9,7.9,2.7l-1.7,0.3c-0.7,0.1-1.2,0.8-1.1,1.5 c0.1,0.4,0.4,0.8,0.8,1l9.4,4.2c0.7,0.3,1.4,0,1.7-0.7c0.1-0.2,0.1-0.3,0.1-0.5l0.1-10.3c0-0.7-0.6-1.3-1.3-1.3 c-0.4,0-0.9,0.2-1.1,0.6l-1.3,1.9c-7.5-7.6-19.6-7.5-27,0c-1.7,1.7-3,3.7-4,6c-0.7,1.6,0.1,3.3,1.6,4C4.8,16.9,5.2,17,5.6,17 L5.6,17z" />
                <path fill="var(--white-2)" d="M36.9,24.6c-0.9-1.4-2.8-1.8-4.2-0.9c-0.5,0.3-0.9,0.8-1.1,1.3c-2,4.9-6.8,8-11.9,8c-2.8,0-5.7-0.9-7.9-2.7 l1.7-0.3c0.7-0.1,1.2-0.8,1.1-1.5c-0.1-0.4-0.4-0.8-0.8-1l-9.4-4.2c-0.7-0.3-1.4,0-1.7,0.7c-0.1,0.2-0.1,0.3-0.1,0.5v10.3 c0,0.7,0.6,1.3,1.3,1.3c0.4,0,0.9-0.2,1.1-0.6l1.3-1.9c7.5,7.4,19.6,7.3,27-0.2c1.7-1.7,3-3.7,4-6C37.6,26.6,37.5,25.4,36.9,24.6z" />
              </svg>
            </a>
            <a onClick={() => setModalCreate(true)} className="button" title="Criar cupom">
              <FiPlus style={{color: "#fff", width: 22, height: 22 }} />
            </a>
          </div>
          
        </form>
      </div>
      <div className="table-c">
      <table className="table">
        <thead>
          <tr>
            <th>Código</th>
            <th>Quantidade</th>
            <th>Cupons Resgatados</th>
            <th>Valor</th>
            <th>Data de criação</th>
            <th>Data de expiração</th>
            <th>Admin</th>
            <th>Status</th>
            <th className="align-right">Menu</th>
          </tr>
        </thead>
        <tbody>
          {
            inserts && inserts.map((insert: any) => (
              <tr>
                <td>{insert?.code}</td>
                <td>{insert?.maxUse}</td>
                <td>{insert?.rescues}</td>
                <td>{insert?.bets}</td>
                <td>{insert?.dateCreate && dayjs(insert?.dateCreate).format("DD/MM/YYYY HH:mm:ss")}</td>
                <td>{insert?.dateExpire && dayjs(insert?.dateExpire).format("DD/MM/YYYY HH:mm:ss")}</td>
                <td>{insert?.admData?.name}</td>
                <td>{statusName[insert?.status as "a" | "i" | "x" | "e"]}</td>
                <td className="align-right">
                  {
                    insert?.status === "i" && insert?.maxUse > insert?.rescues &&
                    <>
                      <a 
                        title="Ativar"
                        onClick={() => setParams({code: insert?.encryptCode, isActive: true})} 
                        className="mini-button"
                      >
                        <FiCheckCircle />
                      </a>
                    </>
                  }
                  {
                    insert?.status === "a" && insert?.maxUse > insert?.rescues &&
                    <>
                      <a 
                        title="Desativar"
                        onClick={() => setParams({code: insert?.encryptCode, isActive: false})} 
                        className="mini-button"
                      >
                        <FiX />
                      </a>
                    </>
                  }
                                    
                </td>
              </tr>
          
            ))
          }
          
        </tbody>
      </table>
      {
        !disableSeeMore &&
        <div className='table-c'>
          <div className="loader-c">
            <a onClick={() => setPage(page + 1)} className="loader">
              <img src={chevronSVG} className="icon" />
              Ver mais
            </a>
          </div>
        </div>
      }
    </div>
    </div>  
    <Modal
      handler={modalCreate}
      onRequestClose={() => {
        setModalCreate(false)
        setPin('')
        setOtpAuth('')
      }}
    >
      <form className='form' onSubmit={(e) => {
        e.preventDefault()
        handleCreateCoupon()
        // console.log(getValues())
        }} style={{alignItems: 'center'}}>
        <div>
          <label className="label" style={{color: "#fff"}}>Código</label>
          <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
            <input
              className='cnt-input'
              type="text"
              id="code"
              {...register("code")}
              required
            />
          </div>
          <label className="label" style={{color: "#fff"}}>Quantidade cupons</label>
          <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
            <input
              className='cnt-input'
              type="number"
              id="amount"
              {...register("amount")}
              required
            />
          </div>
          <label className="label" style={{color: "#fff"}}>Quantidade de lances</label>
          <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
            <input
              className='cnt-input'
              type="number"
              id="amount"
              {...register("value")}
              required
            />
          </div>
          <label className="label" style={{color: "#fff"}}>Data de expiração</label>
          <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
            <input
              className='cnt-input'
              type="datetime-local"
              id="expireDate"
              {...register("expireDate")}
            />
          </div>
          <label className='label' style={{color: "#fff"}} htmlFor="pin" >Senha</label>
          <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
            <input
              type="password"
              name="pswd_fin"
              id="pswd_fin"
              className='cnt-input'
              onChange={(e: any) => setPin(
                e.target.value
              )}
              value={pin}
            />
          </div>
          <label className="label" style={{color: "#fff"}}>2FA</label>
          <div style={{ maxWidth: '400px', margin: '0 auto' }}>
            <input
              onChange={(e: any) => setOtpAuth(
                e.target.value
              )}
              className='cnt-input'
              type="text"
              name="otp"
              id="token"
              value={otpAuth}
            />
            
          </div>

          {
            loading &&
            <svg xmlns="http://www.w3.org/2000/svg" style={{margin: '0 auto', display: 'block', shapeRendering: 'auto'}} width="100px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <path fill="none" stroke="#ffffff" strokeWidth="8" strokeDasharray="42.76482137044271 42.76482137044271" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" strokeLinecap="round" style={{transform: 'scale(0.8)', transformOrigin: '50px 50px'}}>
                <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"></animate>
              </path>
            </svg>
          }
          {
            !loading &&
            <div className="buttons-c">
              <input type="submit" disabled={loading} value="EXECUTAR" className="submit" />
            </div>
          }
        </div>
      </form>
    </Modal>
    <Modal
      handler={modal}
      onRequestClose={() => {
        setModal(false)
        setPin('')
        setOtpAuth('')
        setParams({})
      }}
    >
      <form className='form' onSubmit={(e) => {
        e.preventDefault()
        handleToggleCoupon()
        }} style={{alignItems: 'center'}}>
        <div>
          <label className='label' style={{color: "#fff"}} htmlFor="pin" >Senha</label>
          <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
            <input
              type="password"
              name="pswd_fin"
              id="pswd_fin"
              className='cnt-input'
              onChange={(e: any) => setPin(
                e.target.value
              )}
              value={pin}
            />
          </div>
          <label className="label" style={{color: "#fff"}}>2FA</label>
          <div style={{ maxWidth: '400px', margin: '0 auto' }}>
            <input
              onChange={(e: any) => setOtpAuth(
                e.target.value
              )}
              className='cnt-input'
              type="text"
              name="otp"
              id="token"
              value={otpAuth}
            />
            
          </div>

          {
            loading &&
            <svg xmlns="http://www.w3.org/2000/svg" style={{margin: '0 auto', display: 'block', shapeRendering: 'auto'}} width="100px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <path fill="none" stroke="#ffffff" strokeWidth="8" strokeDasharray="42.76482137044271 42.76482137044271" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" strokeLinecap="round" style={{transform: 'scale(0.8)', transformOrigin: '50px 50px'}}>
                <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"></animate>
              </path>
            </svg>
          }
          {
            !loading &&
            <div className="buttons-c">
              <input type="submit" disabled={loading} value="EXECUTAR" className="submit" />
            </div>
          }
        </div>
      </form>
    </Modal>
  </div>  
    )
}