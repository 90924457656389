/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useContext, useEffect, useState } from "react";
import { FiDollarSign } from "react-icons/fi";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";

import chevronSVG from '../../assets/img/icons/chevron-select.svg';


interface IProps {
  user: any;
}

export function BetsUser({ user }: IProps) {
  const { toggleLoading, loading } = useContext(AuthContext)

  const [balance, setBalance] = useState<any>({})
  const [proofs, setProofs] = useState<Array<any>>([])
  const [page, setPage] = useState(1)
  const [disablePagination, setDisablePagination] = useState(true)

  // --- Get user balance --- //
  async function getUserBalance() {
    toggleLoading(true)
    try {
      const response = await api.post("/manager/cash/user/balance", {userId: user?.encryptId})
      setBalance(response.data)
    } catch(err: any) {
      toast(`${err?.response?.data?.message}`, {
        style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
    }
    toggleLoading(false)
  }
  
  async function getUserProofs() {
    try {
      const response = await api.post("/manager/cash/bets/user/list", {userId: user?.encryptId, page})
      if (page === 1) {
        setDisablePagination(false)
        setProofs(response.data)
      } else if (page > 1 && response.data.length > 0) {
        setDisablePagination(false)
        setProofs([...proofs, ...response.data])
      } else {
        setDisablePagination(true)
      }
    } catch(err: any) {
      toast(`${err?.response?.data?.message}`, {
        style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
    }
  }

  useEffect(() => {
    if (user && user?.id) {
      getUserBalance()
      getUserProofs()
    }
  }, [user])

  useEffect(() => {
    if (user && user?.id) {
      getUserProofs()
    }
  }, [page])

  if (!user?.id) {
    return(
      <div className="content-c active" id="content-2">
        <div className="content">
          <h1>{user?.name ? user?.name : "Selecione um usuário"}</h1>
          <div className="message">Clique no <FiDollarSign /> para visualizar o histórico de transações de um usuário</div>
        </div>
      </div>
    )
  }


  return(
    <>
      <div className="content-c active" id="content-2">
        <div className="content">
          <h1>Histórico de lances (ID:{user?.id} - Login: {user?.userName})</h1>
          <div className="table-c">
              <table className="table">
                <tbody>
                  <tr>
                    <td colSpan={2} className="align-left" style={{borderWidth: 1}}>
                      Balanço financeiro do usuário
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="align-left" style={{borderWidth: 1}}>
                      {/* <h2>Saldo: {balance?.balance?.formatBalance}</h2> */}
                      <h2>Saldo de lances: {balance?.balanceBets?.balance}</h2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            

          <div className="filters">
          </div>
          <div className="table-c">
            <table className="table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Operação</th>
                  <th>Tipo</th>
                  <th>Id de origem</th>
                  <th>Tipo da origem</th>
                  <th className="align-right">Valor</th>
                  <th className="align-right">Balanço</th>
                </tr>
              </thead>
              <tbody>
                {
                  proofs && proofs.map((proof) => {
                    return(
                      <tr>
                        <td>{proof?.id}</td>
                        <td>{proof?.opData?.name}</td>
                        <td>{proof?.type == "d" ? "DÉBITO" : "CRÉDITO"}</td>
                        <td>{proof?.originId}</td>
                        <td>{proof?.originType}</td>
                        <td className="align-right">
                          <a className="mini-button">{Number(proof?.amount)}</a>
                        </td>
                        <td className="align-right">
                          <a className="mini-button">{Number(proof?.balance)}</a>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <div className="loader-c">
              <button disabled={disablePagination || loading} onClick={() => setPage(page + 1)} className={`loader ${disablePagination == true ? 'hidden': ''}`}>
                <img src={chevronSVG} className="icon" />
                Carregar Mais
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}