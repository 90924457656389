/* eslint-disable no-fallthrough */

import { cpf, cnpj } from "cpf-cnpj-validator";

import { CNPJMask, CPFMask, PhoneMask, RandomKeyMask } from "./Masks";

export function PixKeyMaskFormat(
  keyType: string,
  key: string
): { valid: boolean; value: string } {
  // --- Check if have key type --- //
  if (
    !keyType ||
    (keyType !== "EMAIL" &&
      keyType !== "CPF" &&
      keyType !== "CNPJ" &&
      keyType !== "EVP" &&
      keyType !== "PHONE")
  ) {
    return { value: key, valid: false };
  }

  let value = `${key}`;
  let valid = false;
  value = value.toLocaleLowerCase().trim();

  // --- Clear key --- //
  if (keyType === "EMAIL") {
    value = value.replace(/[&='",*<>]/g, "");
    // Validate email
    const re = /\S+@\S+\.\S+/;
    valid = re.test(value);
  }

  if (keyType === "CPF") {
    value = value.replace(/[^0-9]/g, "");
    valid = cpf.isValid(value);
    value = CPFMask(value);
  }

  if (keyType === "CNPJ") {
    value = value.replace(/[^0-9]/g, "");
    valid = cnpj.isValid(value);
    value = CNPJMask(value);
  }

  if (keyType === "EVP") {
    value = value.replace(/[^0-9a-z-]/g, "").slice(0, 36);
    const parceKey = value.split("-");
    if (
      parceKey.length === 5 &&
      parceKey[0].length === 8 &&
      parceKey[1].length === 4 &&
      parceKey[2].length === 4 &&
      parceKey[3].length === 4 &&
      parceKey[4].length === 12
    ) {
      valid = true;
    } else {
      valid = false;
    }

    value = RandomKeyMask(value);
  }

  if (keyType === "PHONE") {
    value = value.replace(/[^0-9]/g, "");

    if (value.length > 9 && value.length < 13) {
      valid = true;
    }

    value = PhoneMask(value);
  }

  return { value, valid };
}
