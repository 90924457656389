import '../../assets/office-login.css';
import { LoginContentContainer } from './styles';
import adminLogo from '../../assets/img/logo.svg';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const schema = Yup.object().shape({
	login: Yup.string().min(9).required('Login is required'),
	password: Yup.string().required('Password is required'),
});

export function Login() {
	const { SignIn } = useContext(AuthContext);
	const [formErrors, setFormErrors]: any[] = useState([]);
	const history = useHistory();

	const {
		register,
		handleSubmit,
		getValues,
		reset,
		formState: { errors },
	} = useForm<any>({
		resolver: yupResolver(schema),
		mode: 'onSubmit',
		reValidateMode: 'onBlur',
	});

	async function signIn(data: any) {
		try {
			// await schema.validate(data, {
			//   abortEarly: false,
			// }).catch((errors) => {
			//   setFormErrors(errors.inner)
			//   throw new Error("Valitarion Error")
			// })
			await SignIn(data?.login, data?.password);
			history.push('/home');
		} catch (err: any) {
			// toast(`${err}`, {
			//   style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
			//   progressStyle: { background: "darkgreen" },
			// });
		}
	}

	return (
		<div className='login-page'>
			<div className='login-cnt' style={{ backgroundImage: '../../assets/img/01.jpg' }}>
				<div className='logo-c'>
					<img src={adminLogo} alt='' className='logo' />
				</div>
				<form
					action='?'
					id='form'
					className='form'
					acceptCharset='utf-8'
					onSubmit={(e) => {
						e.preventDefault();
						signIn(getValues());
					}}
				>
					<input id='login' type='text' className='input' maxLength={50} placeholder='login' {...register('login')} />
					<input id='password' type='password' className='input' maxLength={50} placeholder='senha' {...register('password')} />
					<input type='submit' className='submit' value='ENVIAR' />
					{/* <p>{String(JSON.stringify(errors))}</p> */}
				</form>
				<div className='links'>
					{/* <a href="#" className="link">início</a>
				<a href="#" className="link">esqueci minha senha</a> */}
				</div>
			</div>
		</div>
	);
}
