/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';

import adminLogo from '../../assets/img/logo.svg';
import closeSVG from '../../assets/img/icons/close.svg';
import menuSVG from '../../assets/img/icons/open_menu.svg';
import { AuthContext } from '../../contexts/AuthContext';
import { FiAward, /* FiBarChart,  */ FiServer } from 'react-icons/fi';

export function Header() {
	const { admin, logout } = useContext(AuthContext);

	const [sidebarOpen, setSidebarOpen] = useState('');

	return (
		<>
			<div className={`header ${sidebarOpen}`} id='header'>
				<div className='id'>
					<img src={adminLogo} className='logo' />
					<div className='txt'>{admin.name}</div>
				</div>
				<nav className='menu'>
					<NavLink className='item' activeClassName='active' to='/home'>
						<svg className='icon' version='1.1' x='0px' y='0px' viewBox='0 0 20 20' enableBackground='new 0 0 20 20'>
							<path fillRule='evenodd' clipRule='evenodd' fill='var(--white-2)' d='M2.2,14.4c0-1.2,1-2.2,2.2-2.2l0,0h3.3c1.2,0,2.2,1,2.2,2.2l0,0 v3.3C10,19,9,20,7.8,20l0,0H4.4c-1.2,0-2.2-1-2.2-2.2V14.4z M7.8,14.4H4.4v3.3h3.3L7.8,14.4z' />
							<path fillRule='evenodd' clipRule='evenodd' fill='var(--white-2)' d='M0,2.2C0,1,1,0,2.2,0l0,0h5.6C9,0,10,1,10,2.2l0,0v5.6 C10,9,9,10,7.8,10l0,0H2.2C1,10,0,9,0,7.8l0,0V2.2z M7.8,2.2H2.2v5.6h5.6V2.2z' />
							<path fillRule='evenodd' clipRule='evenodd' fill='var(--white-2)' d='M12.2,14.4c0-1.2,1-2.2,2.2-2.2l0,0h3.3c1.2,0,2.2,1,2.2,2.2l0,0 v3.3c0,1.2-1,2.2-2.2,2.2l0,0h-3.3c-1.2,0-2.2-1-2.2-2.2V14.4z M17.8,14.4h-3.3v3.3h3.3L17.8,14.4z' />
							<path fillRule='evenodd' clipRule='evenodd' fill='var(--white-2)' d='M12.2,4.4c0-1.2,1-2.2,2.2-2.2l0,0h3.3c1.2,0,2.2,1,2.2,2.2l0,0 v3.3C20,9,19,10,17.8,10l0,0h-3.3c-1.2,0-2.2-1-2.2-2.2V4.4z M17.8,4.4h-3.3v3.3h3.3L17.8,4.4z' />
						</svg>
						<div className='txt'>Início</div>
					</NavLink>
					{/* <NavLink className="item" activeClassName="active" to="/referrals">
        <svg className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20.3 20"
          enableBackground="new 0 0 20.3 20">
          <g transform="translate(-0.3 -0.4)">
            <path fill="var(--white-2)"
              d="M6.5,10.5c0,2.2,1.8,4,4,4s4-1.8,4-4s-1.8-4-4-4l0,0C8.3,6.5,6.5,8.3,6.5,10.5z M10.5,8.1 c1.4,0,2.5,1.1,2.5,2.5S11.9,13,10.5,13S8,11.9,8,10.5l0,0C8,9.2,9.1,8.1,10.5,8.1C10.5,8.1,10.5,8.1,10.5,8.1z" />
            <path fill="var(--white-2)"
              d="M16,15.3l-2.3-1c-0.2-0.1-0.4-0.1-0.6,0c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.1,0.4,0,0.6 c0.1,0.2,0.3,0.4,0.5,0.5l1.8,0.7v1.1c0,0.6-0.5,1.2-1.2,1.2c0,0,0,0,0,0H7.3c-0.6,0-1.2-0.5-1.2-1.2c0,0,0,0,0,0v-1.1l1.8-0.7H8 c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.4-0.1-0.6,0l-2.3,1c-0.3,0.1-0.5,0.4-0.5,0.8 v1.6c0,1.5,1.2,2.7,2.7,2.7h6.5c1.5,0,2.7-1.2,2.7-2.7V16C16.5,15.7,16.3,15.4,16,15.3z" />
            <path fill="var(--white-2)"
              d="M5.6,7.2C7.5,7.2,9,5.6,9,3.8c0-1.9-1.5-3.3-3.4-3.4c-1.8,0-3.4,1.5-3.4,3.3c0,0,0,0,0,0 C2.2,5.6,3.7,7.1,5.6,7.2C5.6,7.2,5.6,7.2,5.6,7.2z M5.6,2c1,0,1.8,0.8,1.8,1.8c0,0,0,0,0,0c0,1-0.8,1.8-1.8,1.8 c-1,0-1.8-0.8-1.8-1.8c0,0,0-0.1,0-0.1C3.8,2.8,4.6,2,5.6,2C5.6,2,5.6,2,5.6,2L5.6,2z" />
            <path fill="var(--white-2)"
              d="M5.8,11.8C5.8,11.3,5.5,11,5,11H3.1C2.5,11,2,10.5,1.9,9.9V9.2l1.6-0.6c0.4-0.2,0.6-0.6,0.5-1 C3.8,7.2,3.3,7,2.9,7.1L0.8,7.9C0.5,8,0.3,8.4,0.3,8.7v1.2c0,1.5,1.2,2.7,2.7,2.7h2C5.4,12.6,5.8,12.2,5.8,11.8z" />
            <path fill="var(--white-2)"
              d="M15.4,7.2c1.9,0,3.4-1.5,3.4-3.4c0-1.9-1.6-3.4-3.4-3.3C13.5,0.5,12,2,12,3.8C12,5.6,13.5,7.2,15.4,7.2z  M15.4,2c1,0,1.8,0.8,1.8,1.8c0,0,0,0,0,0c0,1-0.8,1.8-1.8,1.8c0,0,0,0-0.1,0c-1,0-1.8-0.8-1.8-1.8C13.6,2.7,14.4,1.9,15.4,2 L15.4,2z" />
            <path fill="var(--white-2)"
              d="M20.2,7.9l-2.1-0.8c-0.4-0.1-0.9,0.1-1,0.5l0,0C17,7.7,17,7.9,17,8.1c0.1,0.2,0.3,0.4,0.5,0.5l1.6,0.6v0.6 c0,0.6-0.5,1.1-1.1,1.1c0,0,0,0-0.1,0h-2c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h1.9c0.7,0,1.5-0.3,2-0.8 c0.5-0.5,0.8-1.2,0.8-1.9V8.5C20.7,8.3,20.5,8.1,20.2,7.9z" />
          </g>
        </svg>
        <div className="txt">Indicações</div>
      </NavLink> */}
					<NavLink className='item' activeClassName='active' to='/financial'>
						<svg className='icon' version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 20 20' enableBackground='new 0 0 20 20'>
							<path fill='var(--white-2)' d='M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M10,18c-4.4,0-8-3.6-8-8s3.6-8,8-8 s8,3.6,8,8S14.4,18,10,18z' />
							<path
								fill='var(--white-2)'
								d='M10.8,9V7c0.6,0.1,1.1,0.6,1.1,1.2h2c-0.1-1.7-1.4-3-3.1-3.1V3.7H8.9v1.4c-0.5,0-3,0.7-3,2.9 c0,2,1.8,2.5,3,2.7v2.2c-0.7-0.2-1.1-0.8-1.2-1.5h-2c0.1,1.7,1.4,3.2,3.2,3.4v1.4h1.9v-1.4c1.7-0.1,3.1-1.4,3.2-3.1 C14,9.7,12.3,9.2,10.8,9z M8.9,8.7c-1.3,0-1.3-1.5,0-1.7V8.7z M10.8,12.9V11C12.4,11.1,12.6,12.7,10.8,12.9z'
							/>
						</svg>
						<div className='txt'>Financeiro</div>
					</NavLink>
					{/* <NavLink className="item" activeClassName="active" to="/reports">
        <FiBarChart className='icon' />
        <div className="txt">Relatórios</div>
      </NavLink> */}
					<NavLink className='item' activeClassName='active' to='/auction'>
						{/* <img src={require("../../assets/img/icons/support.svg")} alt="" className="icon" /> */}
						<FiAward className='icon' />
						<div className='txt'>Leilões</div>
					</NavLink>
					<NavLink className='item' activeClassName='active' to='/platform'>
						<FiServer className='icon' />
						<div className='txt'>Plataforma</div>
					</NavLink>
					<NavLink className='item' activeClassName='active' to='/profile'>
						<svg className='icon' version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 16.2 20' enableBackground='new 0 0 16.2 20'>
							<g transform='translate(-3.5 -0.5)'>
								<path fill='var(--white-2)' d='M11.6,11.5c3,0,5.5-2.5,5.5-5.5s-2.5-5.5-5.5-5.5S6.1,3,6.1,6c0,0,0,0,0,0C6.1,9,8.5,11.5,11.6,11.5 C11.6,11.5,11.6,11.5,11.6,11.5z M11.6,2.4c2,0,3.5,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6S8,8,8,6C8,4,9.6,2.5,11.6,2.4z' />
								<path
									fill='var(--white-2)'
									d='M19.1,13.5l-3.2-1.9c-0.1-0.1-0.1-0.1-0.2-0.1c-0.5-0.2-1.1,0-1.3,0.5c-0.1,0.2-0.1,0.5,0,0.7 c0.1,0.2,0.3,0.5,0.5,0.5l2.8,1.7v2.1c0,0.9-0.7,1.6-1.6,1.6c0,0,0,0,0,0h-9c-0.9,0-1.6-0.7-1.6-1.6c0,0,0,0,0,0v-2l2.8-1.7 c0.4-0.3,0.5-0.7,0.3-1.2c-0.1-0.2-0.3-0.4-0.5-0.5c-0.3-0.1-0.5-0.1-0.8,0.1L4,13.6c-0.3,0.1-0.5,0.4-0.5,0.8v2.6 c0,2,1.6,3.5,3.6,3.6h9.1c2,0,3.5-1.6,3.6-3.6v-2.6C19.7,14,19.5,13.7,19.1,13.5z'
								/>
							</g>
						</svg>
						<div className='txt'>Perfil</div>
					</NavLink>
					<a className='item' onClick={() => logout()}>
						<svg className='icon' version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 19.9 20' enableBackground='new 0 0 19.9 20'>
							<path fill='var(--white-2)' d='M11.8,1.9v9.8c0,1-0.8,1.9-1.9,1.9s-1.9-0.8-1.9-1.9V1.9c0-1,0.8-1.9,1.9-1.9S11.8,0.8,11.8,1.9z' />
							<path
								fill='var(--white-2)'
								d='M19.9,10.1c0,5.5-4.5,9.9-10,9.9C4.4,20,0,15.5,0,10c0-2.6,1-5.1,2.8-6.9c0.3-0.2,0.6-0.3,0.9-0.3 C4.5,2.8,5,3.3,5.1,4C5,4.4,4.9,4.7,4.6,5c-1.4,1.3-2.2,3.2-2.2,5.1c0,4,3.2,7.3,7.3,7.3c0,0,0.1,0,0.1,0c4,0,7.3-3.2,7.4-7.2 c0,0,0-0.1,0-0.1c0-1.9-0.7-3.7-2.1-5.1c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.7,0.6-1.3,1.3-1.3c0.3,0,0.7,0.1,0.9,0.3 C18.9,4.9,19.9,7.5,19.9,10.1z'
							/>
						</svg>
						<div className='txt'>Sair</div>
					</a>
				</nav>
				<a onClick={() => setSidebarOpen('mob')} className='mob-open' id='mob-open'>
					<img onClick={() => setSidebarOpen('')} src={menuSVG} alt='' className='icon' />
				</a>
				<div className='mob-close' id='mob-close'>
					<img onClick={() => setSidebarOpen('')} src={closeSVG} alt='' className='icon' />
				</div>
			</div>
		</>
	);
}
