/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import chevronSVG from '../../assets/img/icons/chevron-select.svg';
import { api } from '../../services/api';

const statusList: any = {
	a: 'Ativo',
	i: 'Desativado',
};

interface IProps {
	select: Function;
}

export function Banners({ select }: IProps) {
	const [contacts, setContacts] = useState<any[]>([]);
	const [page, setPage] = useState(1);
	const [disableSeeMore, setDisableSeeMore] = useState(false);

	async function searchBanners(reset?: boolean) {
		if (reset) {
			setPage(1);
			setDisableSeeMore(false);
		}
		try {
			const response = await api.get('/manager/banner', { params: { page } });
			if (response.data.length < 1) {
				setDisableSeeMore(true);
				if (reset || page === 1) {
					setContacts([]);
				}
				return;
			} else if (response.data && (reset || page === 1)) {
				setContacts(response.data);
			} else if (response.data.length > 0) {
				const newUsers: [] = response.data;
				setContacts([...contacts, ...newUsers]);
			}
		} catch (err) {}
	}

	useEffect(() => {
		if (page > 1) {
			searchBanners();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		searchBanners(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='content-c active' id='content-2'>
				<div className='content'>
					<h1>Banners</h1>
					<div className='table-c'>
						<table className='table'>
							<thead>
								<tr>
									<th>Imagem</th>
									<th>Id</th>
									<th>Ordem</th>
									<th>Linguagem</th>
									<th>Link</th>
									<th>Status</th>
									<th className='align-right'>Menu</th>
								</tr>
							</thead>
							<tbody>
								{contacts &&
									contacts.map((banner) => (
										<tr key={banner?.id}>
											<td>
												<img src={banner?.image} style={{ maxWidth: 100, maxHeight: 100 }} />
											</td>
											<td>{banner?.id}</td>
											<td>{banner?.order}</td>
											<td>{banner?.language}</td>
											<td onClick={() => window.open(banner?.link)}>
												<a href='#'>{String(banner?.link).substring(0, 30)}</a>
											</td>
											<td>{statusList[banner?.status]}</td>
											<td className='align-right'>
												<a title='Editar banner' onClick={() => select({ ...banner, copy: false, bannerId: banner?.id })} className='mini-button'>
													<FiEdit3 />
												</a>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
					{!disableSeeMore && (
						<div className='table-c'>
							<div className='loader-c'>
								<a onClick={() => setPage(page + 1)} className='loader'>
									<img src={chevronSVG} className='icon' />
									Ver mais
								</a>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
