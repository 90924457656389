/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Modal } from '..';
import { AuthContext } from '../../../contexts/AuthContext';
import { api } from '../../../services/api';


interface ModalProps {
  handler: boolean;
  overlayOpacity?: number;
  onRequestClose: () => void;
  noPadding?: boolean;
  noClose?: boolean;
  width?: number;
  winnerPrize: any;
}


export function UpdateWinnerPrizeModal({ 
  handler, 
  overlayOpacity, 
  onRequestClose, 
  noPadding, 
  noClose = false, 
  width,
  winnerPrize
}: ModalProps) {
  const { toggleLoading, loading } = useContext(AuthContext)
  
  const { register, reset, getValues } = useForm<any>();

  
  async function handleEditAuctionWinnerPrize(data: any) {
    toggleLoading(true)
    try {
      await api.post("/manager/auction/winners/update", data)
      toast(`Informações editadas com sucesso`, {
        style: { backgroundColor: "#00A300", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
     
      reset();
      onRequestClose();
    } catch(err: any) {
      toast(`${err?.response?.data?.message}`, {
        style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
    }
    toggleLoading(false)
  }

  useEffect(() => {
    if (winnerPrize?.auction) {
      reset({
        awId: winnerPrize?.auction,
        prizeType: winnerPrize?.prizeType,
        statusBilling: winnerPrize?.statusBilling,
        statusShipping: winnerPrize?.statusShipping,
        pin: "",
        otpAuth: ""
      })
    }
  }, [winnerPrize])

  return(
    <Modal
        handler={handler}
        onRequestClose={onRequestClose}
        overlayOpacity={overlayOpacity}
        noPadding={noPadding}
        noClose={noClose}
        width={width}
      >
        <h1 style={{color: "#fff", fontSize: 25, marginBottom: 10}}>Leilão {winnerPrize?.auction}</h1>
        <form className='form' onSubmit={(e) => {
          e.preventDefault()
          handleEditAuctionWinnerPrize(getValues())
          }} style={{alignItems: 'center'}}>
          <div>
            <label className='label' style={{color: "#fff"}} htmlFor="prizeType" >Tipo do premio</label>
            <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
              <select className='cnt-input' style={{width: '100%', borderRadius: 8}} {...register('prizeType')}>
                <option value="">Não definido</option>
                <option value="m">Dinheiro</option>
                <option value="p">Produto</option>
              </select>
            </div>
            <label className='label' style={{color: "#fff"}} htmlFor="statusBilling" >Status de pagamento</label>
            <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
              <select className='cnt-input' style={{width: '100%', borderRadius: 8}} {...register('statusBilling')}>
                <option value="w">Aguardando</option>
                <option value="p">Pago</option>
                <option value="x">Cancelado</option>
              </select>
            </div>
            <label className='label' style={{color: "#fff"}} htmlFor="statusShipping" >Status de envio</label>
            <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
              <select className='cnt-input' style={{width: '100%', borderRadius: 8}} {...register('statusShipping')}>
                <option value="w">Aguardando</option>
                <option value="s">Enviado</option>
                <option value="x">Cancelado</option>
              </select>
            </div>
            <label className='label' style={{color: "#fff"}} htmlFor="pin" >Senha</label>
            <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
              <input
                type="password"
                id="pin"
                className='cnt-input'
                {...register('pin')}
                required
              />
            </div>
            
            <label className="label" style={{color: "#fff"}}>2FA</label>
            <div style={{ maxWidth: '400px', margin: '0 auto' }}>
              <input
                className='cnt-input'
                type="text"
                {...register('otpAuth')}
                id="otpAuth"
                required
              />
              
            </div>

            {
              loading &&
              <svg xmlns="http://www.w3.org/2000/svg" style={{margin: '0 auto', display: 'block', shapeRendering: 'auto'}} width="100px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <path fill="none" stroke="#ffffff" strokeWidth="8" strokeDasharray="42.76482137044271 42.76482137044271" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" strokeLinecap="round" style={{transform: 'scale(0.8)', transformOrigin: '50px 50px'}}>
                  <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"></animate>
                </path>
              </svg>
            }
            {
              !loading &&
              <div className="buttons-c">
                <input type="submit" disabled={loading} value="EDITAR" className="submit" />
                {/* <input type="reset" value="CANCELAR" className="reset" /> */}
              </div>
            }
          </div>
        </form>
      </Modal>
  )
}