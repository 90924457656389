/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/AuthContext';
import { api } from '../../services/api';

import chevronSVG from '../../assets/img/icons/chevron-select.svg';
import { FiCopy, FiEdit3 } from 'react-icons/fi';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface IProps {
	select: Function;
}

const statusList: any = {
	a: 'Ativo',
	i: 'Inativo',
};

export function Plans({ select }: IProps) {
	const { toggleLoading, loading } = useContext(AuthContext);

	const [plans, setAuctions] = useState<Array<any>>([]);
	const [page, setPage] = useState(1);
	const [disablePagination, setDisablePagination] = useState(true);

	async function searchPlans() {
		toggleLoading(true);
		try {
			const response = await api.get('/plans/manager', { params: { page } });
			if (page === 1) {
				setDisablePagination(false);
				setAuctions(response.data);
			} else if (page > 1 && response.data.length > 0) {
				setDisablePagination(false);
				setAuctions([...plans, ...response.data]);
			}
			if (response.data.length < 25) {
				setDisablePagination(true);
			}
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		toggleLoading(false);
	}

	useEffect(() => {
		searchPlans();
	}, [page]);

	return (
		<>
			<div className='content-c active' id='content-2'>
				<div className='content'>
					<h1>Planos</h1>
					{/* <div className="filters">
            <select className="select" {...register('status')}>
              <option value=''>Todos</option>
              <option value='a'>Ativos</option>
              <option value='i'>Inativos</option>
              <option value='e'>Encerrados</option>
            </select>
            <form onSubmit={(e) => {
              e.preventDefault();
                if (page === 1) {
                  searchPlans()
                } else {
                  setPage(1)
                }
              }}>
              <input
                id="search"
                type="text"
                className="search"
                placeholder="Search"
                {...register('search')}
              />
              <div className="buttons-c">
                <button type="submit" className="button">
                  <svg className="img" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" >
                    <path fill="var(--white-2)" d="M37.9,38c-1.4,1.4-3.7,1.4-5,0l0,0l-6.3-6.3c-7.7,5-18,2.7-23-5S0.9,8.6,8.6,3.6s18-2.7,23,5 c3.6,5.5,3.6,12.5,0,18l6.3,6.3C39.4,34.3,39.4,36.6,37.9,38L37.9,38z M17.7,5.9c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8 s11.8-5.3,11.8-11.8S24.3,5.9,17.7,5.9L17.7,5.9z"/>
                  </svg>
                </button>
              </div>

            </form>
          </div> */}
					<div className='table-c'>
						<table className='table'>
							<thead>
								<tr>
									<th>Imagem</th>
									<th>Id</th>
									<th>Nome</th>
									<th>Valor</th>
									<th>Lances</th>
									<th>Status</th>
									<th className='align-right'>Menu</th>
								</tr>
							</thead>
							<tbody>
								{plans &&
									plans.map((plan) => {
										return (
											<tr key={plan?.id}>
												<td>
													<img src={plan?.image} style={{ maxWidth: 100, maxHeight: 100 }} />
												</td>
												<td>{plan?.id}</td>
												<td>{String(plan?.name).substring(0, 30)}</td>
												<td>
													{new Intl.NumberFormat('pt-br', {
														style: 'currency',
														currency: 'BRL',
													}).format(plan?.price ? Number(plan?.price) : 0)}
												</td>
												<td>{plan?.bets}</td>
												<td>{statusList[plan?.status]}</td>
												<td className='align-right'>
													<a title='Copiar plano' onClick={() => select({ ...plan, id: null, copy: true })} className='mini-button'>
														<FiCopy />
													</a>
													<a title='Editar plano' onClick={() => select({ ...plan, copy: false, planId: plan?.id })} className='mini-button'>
														<FiEdit3 />
													</a>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
						<div className='loader-c'>
							<button disabled={disablePagination || loading} onClick={() => setPage(page + 1)} className={`loader ${disablePagination === true ? 'hidden' : ''}`}>
								<img src={chevronSVG} className='icon' />
								Carregar Mais
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
