export function PhoneMask(value: string): string {
  if (!value) {
    return value;
  }

  const str = String(value)
    .replace(/[^0-9]/g, "")
    .slice(0, 11);
  let result = str;

  const regex = /^([0-9]{2})([0-9]{0,4})([0-9]{0,4})$/;

  const regex2 = /^([0-9]{2})([0-9]{5})([0-9]{4})$/;

  if (str.length <= 10) {
    result = str.replace(
      regex,

      `($1${str.length <= 2 ? "" : ") "}$2${str.length <= 6 ? "" : "-"}$3`
    );
  } else {
    result = str.replace(regex2, "($1) $2-$3");
  }

  return result;
}

export function CPFMask(value: string): string {
  if (!value) {
    return value;
  }

  const str = String(value)
    .replace(/[^0-9]/g, "")
    .slice(0, 11);

  let result = str;

  const regex = /^([0-9]{3})([0-9]{0,3})([0-9]{0,3})([0-9]{0,2})$/;
  const regex2 = /^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})$/;

  if (str.length <= 10) {
    const l = str.length;
    result = str.replace(
      regex,
      `$1${l <= 3 ? "" : "."}$2${l <= 6 ? "" : "."}$3${l <= 9 ? "" : "-"}$4`
    );
  } else {
    result = str.replace(regex2, "$1.$2.$3-$4");
  }

  return result;
}

export function CNPJMask(value: string): string {
  if (!value) {
    return value;
  }

  const str = String(value)
    .replace(/[^0-9]/g, "")
    .slice(0, 14);

  let result = str;

  const regex = /^([0-9]{2})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})([0-9]{0,2})$/;
  const regex2 = /^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})$/;

  if (str.length <= 10) {
    const l = str.length;

    result = str.replace(
      regex,
      `$1${l <= 2 ? "" : "."}$2${l <= 5 ? "" : "."}$3${l <= 9 ? "" : "/"}$4${
        l <= 12 ? "" : "-"
      }$5`
    );
  } else {
    result = str.replace(regex2, "$1.$2.$3/$4-$5");
  }

  return result;
}

export function RandomKeyMask(value: string): string {
  if (!value) {
    return value;
  }

  const str = String(value)
    .replace(/[^0-9a-z]/g, "")
    .slice(0, 32);

  let result = str;

  const regex = /^([0-9a-z]{8})([0-9a-z]{0,4})([0-9a-z]{0,4})([0-9a-z]{0,4})([0-9a-z]{0,12})$/;
  const regex2 = /^([0-9a-z]{8})([0-9a-z]{4})([0-9a-z]{4})([0-9a-z]{4})([0-9a-z]{12})$/;

  if (str.length <= 31) {
    const l = str.length;

    result = str.replace(
      regex,
      `$1${l <= 8 ? "" : "-"}$2${l <= 12 ? "" : "-"}$3${l <= 16 ? "" : "-"}$4${
        l <= 20 ? "" : "-"
      }$5`
    );
  } else {
    result = str.replace(regex2, "$1-$2-$3-$4-$5");
  }

  return result;
}
