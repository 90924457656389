/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FiSmartphone, FiMail, FiEdit3, FiKey, FiDollarSign, FiSlash } from 'react-icons/fi';
import { Modal } from '../Modal';
import { api } from '../../services/api';
import { useState } from 'react';
import { toast } from 'react-toastify';
// import { useForm } from 'react-hook-form';
import { BlackListModal } from '../Modal/BlackListModal';

interface IProps {
	users: Array<any>;
	selectedUser: Function;
	selectTab: Function;
}

let func: any;

export function UsersTable({ users, selectedUser, selectTab }: IProps) {
	const [loading, setLoading] = useState(false);
	const [pin, setPin] = useState('');
	const [otpAuth, setOtpAuth] = useState('');
	const [modal, setModal] = useState(false);
	const [modalBlackList, setModalBlackList] = useState(false);
	const [modalForgotPswd, setModalForgotPswd] = useState(false);
	const [params, setParams] = useState<any>();
	const [user, setUser] = useState<any>();

	// --- Reset password --- //
	async function handleSendMailForgotPassword(userName: string) {
		setLoading(true);
		try {
			await api.post('/manager/users/forgot-password', { userName });
			toast(`E-mail encaminhado com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
		setModalForgotPswd(false);
	}

	// --- Reset user otp ---//
	async function handleResetOtp(userId: string) {
		setLoading(true);
		try {
			await api.post('/manager/users/reset-otp', { userId, otpAuth, pin });
			setModal(false);
			setParams(false);
			setOtpAuth('');
			setPin('');
			toast(`OTP/2FA resetado com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		} catch (err: any) {
			setModal(false);
			setOtpAuth('');
			setPin('');
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
	}

	// --- Reset user pin ---//
	async function handleResetPIN(userId: string) {
		setLoading(true);
		try {
			await api.post('/manager/users/reset-pin', { userId, otpAuth, pin });
			setModal(false);
			setParams(false);
			setOtpAuth('');
			setPin('');
			toast(`OTP/2FA resetado com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		} catch (err: any) {
			setModal(false);
			setOtpAuth('');
			setPin('');
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
	}

	// --- Open user in new tab --- //
	function handleChangeTab(userId: string, tab: string) {
		selectedUser(userId);
		selectTab(tab);
	}

	function handleSetParamsAndOpenModal(p: any, f: Function) {
		setParams(p);
		func = f;
		setModal(true);
	}

	// --- Execute function --- //
	function modalSendRequest() {
		if (func) {
			const execute = func;
			execute(params);
		}
	}
	return (
		<div className='table-c'>
			<table className='table'>
				<thead>
					<tr>
						<th>Id</th>
						<th>Nome</th>
						<th>Login</th>
						<th>E-mail</th>
						<th>Documento</th>
						<th className='align-right'>Menu</th>
					</tr>
				</thead>
				<tbody>
					{users &&
						users.map((user) => (
							<tr key={user?.id}>
								<td>{user?.id}</td>
								<td>{user?.name}</td>
								<td>{user?.userName}</td>
								<td>{user?.email}</td>
								<td>{user?.document}</td>
								<td className='align-right'>
									<a
										title='Recuperação de senha'
										onClick={() => {
											setUser({
												user: Number(user?.id),
												userName: user?.userName,
											});
											setModalForgotPswd(true);
										}}
										className='mini-button'
									>
										<FiMail />
									</a>
									<a title='Resetar OTP/2FA' onClick={() => handleSetParamsAndOpenModal(user?.encryptId, handleResetOtp)} className='mini-button'>
										<FiSmartphone />
									</a>
									<a title='Resetar senha financeira' onClick={() => handleSetParamsAndOpenModal(user?.encryptId, handleResetPIN)} className='mini-button'>
										<FiKey />
									</a>
									<a title='Histórico de lances' onClick={() => handleChangeTab(user, 'content-5')} className='mini-button'>
										<FiDollarSign />
									</a>
									<a
										title='Inserir usuário na blacklist'
										onClick={() => {
											setUser({
												user: Number(user?.id),
												userName: user?.userName,
											});
											setModalBlackList(true);
										}}
										className='mini-button'
									>
										<FiSlash />
									</a>
									<a title='Editar usuário' onClick={() => handleChangeTab(user, 'content-3')} className='mini-button'>
										<FiEdit3 />
									</a>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			<BlackListModal
				handler={modalBlackList}
				onRequestClose={() => {
					setModalBlackList(false);
				}}
				user={user}
			/>
			<Modal
				handler={modal}
				onRequestClose={() => {
					setModal(false);
				}}
			>
				<form
					className='form'
					onSubmit={(e) => {
						e.preventDefault();
						modalSendRequest();
					}}
					style={{ alignItems: 'center' }}
				>
					<div>
						<label className='label' style={{ color: '#fff' }} htmlFor='pin'>
							Senha
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<input type='password' name='pswd_fin' id='pswd_fin' className='cnt-input' onChange={(e: any) => setPin(e.target.value)} value={pin} />
						</div>
						<label className='label' style={{ color: '#fff' }}>
							2FA
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto' }}>
							<input onChange={(e: any) => setOtpAuth(e.target.value)} className='cnt-input' type='text' name='otp' id='token' value={otpAuth} />
						</div>

						{loading && (
							<svg xmlns='http://www.w3.org/2000/svg' style={{ margin: '0 auto', display: 'block', shapeRendering: 'auto' }} width='100px' height='60px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
								<path
									fill='none'
									stroke='#ffffff'
									strokeWidth='8'
									strokeDasharray='42.76482137044271 42.76482137044271'
									d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z'
									strokeLinecap='round'
									style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}
								>
									<animate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0;256.58892822265625'></animate>
								</path>
							</svg>
						)}
						{!loading && (
							<div className='buttons-c'>
								<input type='submit' disabled={loading} value='EXECUTAR' className='submit' />
								{/* <input type="reset" value="CANCELAR" className="reset" /> */}
							</div>
						)}
					</div>
				</form>
			</Modal>
			<Modal
				handler={modalForgotPswd}
				onRequestClose={() => {
					setModalForgotPswd(false);
				}}
			>
				<form
					className='form'
					onSubmit={(e) => {
						e.preventDefault();
						handleSendMailForgotPassword(user?.userName);
					}}
					style={{ alignItems: 'center' }}
				>
					<div>
						{loading && (
							<svg xmlns='http://www.w3.org/2000/svg' style={{ margin: '0 auto', display: 'block', shapeRendering: 'auto' }} width='100px' height='60px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
								<path
									fill='none'
									stroke='#ffffff'
									strokeWidth='8'
									strokeDasharray='42.76482137044271 42.76482137044271'
									d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z'
									strokeLinecap='round'
									style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}
								>
									<animate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0;256.58892822265625'></animate>
								</path>
							</svg>
						)}
						{!loading && (
							<div className='buttons-c'>
								<input type='submit' disabled={loading} value='ENVIAR EMAIL' className='submit' />
								{/* <input type="reset" value="CANCELAR" className="reset" /> */}
							</div>
						)}
					</div>
				</form>
			</Modal>
		</div>
	);
}
