/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useContext, useEffect, useState } from 'react';
import chevronSVG from '../../assets/img/icons/chevron-select.svg';
import { DepositsTable } from '../../components/tables/DepositsTable';
import { AuthContext } from '../../contexts/AuthContext';
import { api } from '../../services/api';

export function Deposits() {
	const { toggleLoading } = useContext(AuthContext);
	const [deposits, setDeposits] = useState([]);
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [status, setStatus] = useState('');
	const [valueSearch, setValueSearch] = useState('');
	const [disableSeeMore, setDisableSeeMore] = useState(false);

	async function searchDeposits(reset?: boolean) {
		toggleLoading(true);
		if (reset) {
			setPage(1);
			setDisableSeeMore(false);
		}
		try {
			const response = await api.post('/manager/deposits/list', { page: reset ? 1 : page, search, status });
			if (response.data.length < 1) {
				setDisableSeeMore(true);
				if (reset || page === 1) {
					setDeposits([]);
				}
				toggleLoading(false);
				return;
			} else if (response.data && (reset || page === 1)) {
				setDeposits(response.data);
			} else if (response.data.length > 0) {
				const newDeposits: [] = response.data;
				setDeposits([...deposits, ...newDeposits]);
			}
		} catch (err) {}
		toggleLoading(false);
	}

	useEffect(() => {
		if (page > 1) {
			searchDeposits();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		searchDeposits(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, status]);

	return (
		<div className='content-c active' id='content-2'>
			{/* <div className="mbutton-c">
				<a onClick={() => console.log("manageDisplayModal('content-2');")} className="button">
					<svg className="img" version="1.1" xmlns="http://www.w3.org/2000/svg"  enableBackground="new 0 0 20 20" >
						<path fill="var(--white-2)" d="M14.7,13.4L11.3,10l3.4-3.4c0.4-0.4,0.4-1,0-1.3c-0.4-0.4-1-0.4-1.3,0L10,8.7L6.6,5.3c-0.4-0.4-1-0.4-1.3,0 c-0.4,0.4-0.4,1,0,1.3L8.7,10l-3.4,3.4c-0.4,0.4-0.4,1,0,1.3c0.4,0.4,1,0.4,1.3,0l3.4-3.4l3.4,3.4c0.4,0.4,1,0.4,1.3,0 C15.1,14.4,15.1,13.8,14.7,13.4z"/>
						<path fill="var(--white-2)" d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M10,19c-5,0-9-4-9-9s4-9,9-9s9,4,9,9 S15,19,10,19z"/>
					</svg>
				</a>
			</div> */}

			<div className='content'>
				<h1>Solicitações de depósitos</h1>
				<div className='filters'>
					<select name='options' className='select' onChange={(e) => setStatus(e.target.value)}>
						<option value=''>Todos</option>
						<option value='p'>Pagos</option>
						<option value='w'>Aguardando</option>
						<option value='x'>Cancelados</option>
					</select>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							setSearch(valueSearch);
						}}
					>
						<input id='search' type='text' className='search' placeholder='Buscar por id | usuário' onChange={(e) => setValueSearch(e.target.value)} />
						<div className='buttons-c'>
							<a onClick={() => setSearch(valueSearch)} className='button'>
								<svg className='img' version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 40 40'>
									<path
										fill='var(--white-2)'
										d='M37.9,38c-1.4,1.4-3.7,1.4-5,0l0,0l-6.3-6.3c-7.7,5-18,2.7-23-5S0.9,8.6,8.6,3.6s18-2.7,23,5 c3.6,5.5,3.6,12.5,0,18l6.3,6.3C39.4,34.3,39.4,36.6,37.9,38L37.9,38z M17.7,5.9c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8 s11.8-5.3,11.8-11.8S24.3,5.9,17.7,5.9L17.7,5.9z'
									/>
								</svg>
							</a>
							<a onClick={() => searchDeposits()} className='button'>
								<svg className='img' version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 40 40'>
									<path
										fill='var(--white-2)'
										d='M5.6,17c1.2,0,2.3-0.7,2.8-1.8c2-4.9,6.8-8,11.9-8c2.8,0,5.7,0.9,7.9,2.7l-1.7,0.3c-0.7,0.1-1.2,0.8-1.1,1.5 c0.1,0.4,0.4,0.8,0.8,1l9.4,4.2c0.7,0.3,1.4,0,1.7-0.7c0.1-0.2,0.1-0.3,0.1-0.5l0.1-10.3c0-0.7-0.6-1.3-1.3-1.3 c-0.4,0-0.9,0.2-1.1,0.6l-1.3,1.9c-7.5-7.6-19.6-7.5-27,0c-1.7,1.7-3,3.7-4,6c-0.7,1.6,0.1,3.3,1.6,4C4.8,16.9,5.2,17,5.6,17 L5.6,17z'
									/>
									<path
										fill='var(--white-2)'
										d='M36.9,24.6c-0.9-1.4-2.8-1.8-4.2-0.9c-0.5,0.3-0.9,0.8-1.1,1.3c-2,4.9-6.8,8-11.9,8c-2.8,0-5.7-0.9-7.9-2.7 l1.7-0.3c0.7-0.1,1.2-0.8,1.1-1.5c-0.1-0.4-0.4-0.8-0.8-1l-9.4-4.2c-0.7-0.3-1.4,0-1.7,0.7c-0.1,0.2-0.1,0.3-0.1,0.5v10.3 c0,0.7,0.6,1.3,1.3,1.3c0.4,0,0.9-0.2,1.1-0.6l1.3-1.9c7.5,7.4,19.6,7.3,27-0.2c1.7-1.7,3-3.7,4-6C37.6,26.6,37.5,25.4,36.9,24.6z'
									/>
								</svg>
							</a>
						</div>
					</form>
				</div>
				<DepositsTable deposits={deposits} callBack={searchDeposits} />
				{!disableSeeMore && (
					<div className='table-c'>
						<div className='loader-c'>
							<a onClick={() => setPage(page + 1)} className='loader'>
								<img src={chevronSVG} className='icon' />
								Ver mais
							</a>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
