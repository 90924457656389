/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiEdit3 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Modal } from '../../components/Modal';
import { api } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import { CPFMask, PhoneMask } from '../../functions/PIX/Masks';
import { PixKeyMaskFormat } from '../../functions/PIX/PixKey';
import { ICountry, ISearchAddress, IState } from '../../dtos/AddressDTO';

interface IProps {
	user: any;
}

let func: any;

export function UserInfo({ user }: IProps) {
	const { toggleLoading } = useContext(AuthContext);

	const [loading, setLoading] = useState(false);
	// const [userInfo, setUserInfo] = useState<any>({});
	const [modalUpdate, setModalUpdate] = useState(false);
	const [modal, setModal] = useState(false);
	const [params, setParams] = useState<any>();
	const [pin, setPin] = useState('');
	const [otpAuth, setOtpAuth] = useState('');

	const [countries, setCountries] = useState<ICountry[]>([]);
	const [states, setStates] = useState<IState[]>([]);
	const [city, setCity] = useState<any[]>([]);

	const { register, getValues, reset, setValue } = useForm<any>({
		mode: 'onSubmit',
		reValidateMode: 'onBlur',
	});

	async function getUserInfo(userId: string) {
		if (!userId || userId.length == 0) {
			return;
		}

		toggleLoading(true);
		setLoading(true);
		try {
			const response = await api.post('/manager/users/user-info', { userId });
			const key = response.data?.pix;
			const keyTypes = ['CPF', 'CNPJ', 'EMAIL', 'PHONE', 'EVP'];

			let check: any;
			for (let index = 0; index < keyTypes.length; index++) {
				const element = keyTypes[index];
				const response = PixKeyMaskFormat(element, key);
				if (response.valid === true) {
					check = { ...response, keyType: element };
					break;
				}
			}
			if (response.data?.country && response.data?.city) {
				await Promise.all([searchAddress({ type: 'country', search: '' }), searchAddress({ type: 'state', search: '', country: Number(response.data?.country) }), searchAddress({ type: 'city', search: '', state: Number(response.data?.cityData?.state) })]);
			} else {
				searchAddress({ type: 'country', search: '' });
			}

			reset({
				encryptId: response.data?.encryptId,
				name: response.data?.name,
				email: response.data?.email,
				document: CPFMask(response.data?.document || ''),
				userName: response.data?.userName,
				status: response.data?.status,
				referral: response.data?.referral,
				birth: response.data?.birth,
				postalCode: response.data?.postalCode,
				city: response.data?.city,
				address: response.data?.address,
				country: response.data?.country,
				state: response.data?.cityData?.state,
				cellPhone: PhoneMask(response.data?.cellPhone || ''),
				phone: PhoneMask(response.data?.phone || ''),
				secondEmail: response.data?.secondEmail,
				pix: check?.valid === true ? check?.value : response.data?.pix,
				keyType: check?.valid === true ? check?.keyType : '',
				emailCheck: response.data?.emailCheck,
				termsCheck: response.data?.termsCheck,
			});
			// setUserInfo(response.data);
		} catch {}
		setLoading(false);
		toggleLoading(false);
	}

	// --- Reset password --- //
	async function handleSendMailForgotPassword(email: string) {
		toggleLoading(true);
		setLoading(true);
		try {
			await api.post('/manager/users/forgot-password', { email });
			toast(`E-mail encaminhado com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
		toggleLoading(false);
	}

	// --- Reset user otp ---//
	async function handleResetOtp(data: any) {
		toggleLoading(true);
		setLoading(true);
		try {
			await api.post('/manager/users/reset-otp', data);
			setModal(false);
			setParams(false);
			setOtpAuth('');
			setPin('');
			toast(`OTP/2FA resetado com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		} catch (err: any) {
			setModal(false);
			setOtpAuth('');
			setPin('');
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
		toggleLoading(false);
	}

	// --- Reset user pin ---//
	async function handleResetPIN(data: any) {
		toggleLoading(true);
		setLoading(true);
		try {
			await api.post('/manager/users/reset-pin', data);
			setModal(false);
			setParams(false);
			setOtpAuth('');
			setPin('');
			toast(`OTP/2FA resetado com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		} catch (err: any) {
			setModal(false);
			setOtpAuth('');
			setPin('');
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
		toggleLoading(false);
	}

	async function handleUpdateUser(data: any) {
		toggleLoading(true);
		setLoading(true);
		try {
			await api.post('/manager/users/update-profile', { ...data, pin, otpAuth, encryptId: user?.encryptId });
			toast(`Usuário atualizado com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			setPin('');
			setOtpAuth('');
			setModalUpdate(false);
			await getUserInfo(user?.encryptId);
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
		toggleLoading(false);
	}

	// async function handleToggleReferralStatus(data: any) {
	//   toggleLoading(true)
	//   setLoading(true)
	//   try {
	//     await api.post("/manager/users/referral-status", data)
	//     toast(`Indicações ${data?.enabled == true ? 'ativadas' : 'desativadas'} com sucesso!`, {
	//       style: { backgroundColor: "#00A300", color: "#fff", position: "absolute" },
	//       progressStyle: { background: "darkgreen" },
	//     });
	//     setPin('')
	//     setOtpAuth('')
	//     setModal(false)
	//     await getUserInfo(user?.encryptId)
	//   } catch(err: any) {
	//     setPin('')
	//     setOtpAuth('')
	//     setModal(false)
	//     toast(`${err?.response?.data?.message}`, {
	//       style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
	//       progressStyle: { background: "darkgreen" },
	//     });
	//   }
	//   setLoading(false)
	//   toggleLoading(false)
	// }

	function handleSetParamsAndOpenModal(p: any, f: Function) {
		setParams(p);
		func = f;
		setModal(true);
	}

	async function searchAddress({ type, search, state, country }: ISearchAddress) {
		try {
			const response = await api.post('/manager/users/address-search', { type, search, state, country });
			if (type == 'country') {
				setCountries(response.data);
			} else if (type == 'state') {
				setStates(response.data);
			} else if (type == 'city') {
				setCity(response.data);
			}
		} catch (err) {}
	}

	useEffect(() => {
		if (user) {
			getUserInfo(user?.encryptId);
		}
	}, [user]);

	if (!user?.id) {
		return (
			<div className='content-c active' id='content-2'>
				<div className='content'>
					<h1>{user?.name ? `${user?.userName}-${user?.name}` : 'Selecione um usuário'}</h1>
					<div className='message'>
						Clique no <FiEdit3 /> para visualizar/editar um usuário
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className='content-c active' id='content-2'>
				<div className='content'>
					<h1>{user?.name ? `${user?.userName}-${user?.name}` : 'Selecione um usuário'}</h1>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							setModalUpdate(true);
						}}
						className='form'
						acceptCharset='utf-8'
						method='post'
					>
						{!user && (
							<div className='message'>
								Clique no <FiEdit3 /> para visualizar/editar um usuário
							</div>
						)}
						<div className='table-c'>
							<table className='table'>
								<tbody>
									<tr>
										<td colSpan={2} className='align-left' style={{ borderWidth: 1 }}>
											Ações do usuário
										</td>
									</tr>
									<tr>
										<td colSpan={2} className='align-left' style={{ borderWidth: 1 }}>
											<a title='Recuperação de senha' onClick={() => handleSendMailForgotPassword(user?.email)} className='mini-button'>
												Recuperar senha
											</a>
											<a title='Resetar OTP/2FA' onClick={() => handleSetParamsAndOpenModal({ userId: user?.encryptId }, handleResetOtp)} className='mini-button'>
												Resetar OTP/2FA
											</a>
											<a title='Resetar senha financeira' onClick={() => handleSetParamsAndOpenModal({ userId: user?.encryptId }, handleResetPIN)} className='mini-button'>
												Resetar senha financeira
											</a>
										</td>
									</tr>
									{/* <tr>
                  <td colSpan={2} className="align-left" style={{borderWidth: 1}}>
                    <a
                      title={`${userInfo?.referralStatus == "a" ? "Desabilitar" : "Habilitar"} indicações`}
                      onClick={() => handleSetParamsAndOpenModal({userId: user?.encryptId, enable: userInfo?.referralStatus == "a" ? false : true}, handleToggleReferralStatus)}
                      className="mini-button"
                    >
                      {`${userInfo?.referralStatus == "a" ? "Desabilitar" : "Habilitar"} indicações`}
                    </a>
                  </td>
                </tr> */}
								</tbody>
							</table>
						</div>

						<input type='text' title='Nome' className='input wd-100' placeholder='Nome' {...register('name')} />
						<input
							id='document'
							type='text'
							title='Documento'
							className='input wd-75'
							placeholder='Documento'
							{...register('document')}
							onChange={(e) => {
								const value = e.target.value;
								const result = CPFMask(value);
								setValue('document', result);
							}}
						/>
						<input type='text' title='Login' className='input wd-25' placeholder='Login' {...register('userName')} />
						<select
							id='country'
							{...register('country')}
							value={getValues()?.country}
							title='Selecione o país'
							className='select wd-33'
							placeholder='Selecione o país'
							onChange={(e) => {
								searchAddress({ type: 'state', search: '', country: Number(e.target.value) });
								setValue('country', e.target.value);
							}}
						>
							<option disabled value=''>
								Selecione seu o país
							</option>
							{countries && countries.map((c) => <option value={`${c.id}`}>{c.name}</option>)}
						</select>
						<select
							id='state'
							{...register('state')}
							value={getValues()?.state}
							title='Selecione o estado'
							className='select wd-33'
							placeholder='Selecione o estado'
							onChange={(e) => {
								searchAddress({ type: 'city', search: '', state: Number(e.target.value) });
								setValue('state', e.target.value);
							}}
						>
							<option disabled value=''>
								Selecione o estado
							</option>
							{states && states.map((c) => <option value={`${c.id}`}>{c.name}</option>)}
						</select>
						<select id='city' {...register('city')} value={getValues()?.city} title='Selecione a cidade' className='select wd-33' placeholder='Selecione a cidade'>
							<option disabled value=''>
								Selecione a cidade
							</option>
							{city && city.map((c) => <option value={`${c.id}`}>{c.name}</option>)}
						</select>
						<input type='text' title='CEP' className='input wd-33' placeholder='CEP' {...register('postalCode')} />
						<input type='text' title='Endereço' className='input wd-100' placeholder='Endereço' {...register('address')} />
						<input
							id='phone'
							type='text'
							title='Telefone'
							className='input wd-50'
							placeholder='Telefone'
							{...register('phone')}
							onChange={(e) => {
								const value = e.target.value;
								const result = PhoneMask(value);
								setValue('phone', result);
							}}
						/>
						<input
							id='cellPhone'
							type='text'
							title='Celular'
							className='input wd-50'
							placeholder='Celular'
							{...register('cellPhone')}
							onChange={(e) => {
								const value = e.target.value;
								const result = PhoneMask(value);
								setValue('cellPhone', result);
							}}
						/>
						<div className='input-c'>
							<input type='text' title='E-mail' className='input wd-100' placeholder='E-mail' {...register('email')} />
						</div>
						<select {...register('keyType')} title='Tipo da chave' className='select wd-33' placeholder='Tipo da chave'>
							<option value='' disabled>
								SELECIONE O TIPO DA CHAVE
							</option>
							<option value='CPF'>CPF</option>
							<option value='CNPJ'>CNPJ</option>
							<option value='EMAIL'>E-mail</option>
							<option value='PHONE'>Celular</option>
							<option value='EVP'>Chave randomica</option>
						</select>
						<input
							id='pix'
							type='text'
							title='PIX'
							className='input wd-50'
							placeholder='PIX'
							{...register('pix')}
							onChange={(e) => {
								const value = e.target.value;
								const key = getValues()?.keyType || 'CPF';
								const result = PixKeyMaskFormat(key, value);
								setValue('pix', result.value);
							}}
						/>
						<select {...register('status')} title='Status do usuário' className='select wd-33' placeholder='Selecione uma cidade'>
							<option value='a'>Ativo</option>
							<option value='i'>Inativo</option>
							<option value='p' disabled>
								Pendente
							</option>
						</select>
						<div className='buttons-c'>
							<input type='submit' value='ATUALIZAR' className='submit' />
						</div>
					</form>
				</div>
			</div>
			<Modal
				handler={modalUpdate}
				onRequestClose={() => {
					setModalUpdate(false);
					setPin('');
					setOtpAuth('');
				}}
			>
				<form
					className='form'
					onSubmit={(e) => {
						e.preventDefault();
						handleUpdateUser(getValues());
					}}
					style={{ alignItems: 'center' }}
				>
					<div>
						<label className='label' style={{ color: '#fff' }} htmlFor='pin'>
							Senha
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<input type='password' name='pswd_fin' id='pswd_fin' className='cnt-input' onChange={(e: any) => setPin(e.target.value)} value={pin} />
						</div>
						{/* <label className="label" style={{color: "#fff"}}>2FA</label>
            <div style={{ maxWidth: '400px', margin: '0 auto' }}>
              <input
                onChange={(e: any) => setOtpAuth(
                  e.target.value
                )}
                className='cnt-input'
                type="text"
                name="otp"
                id="token"
                value={otpAuth}
              />

            </div> */}

						{loading && (
							<svg xmlns='http://www.w3.org/2000/svg' style={{ margin: '0 auto', display: 'block', shapeRendering: 'auto' }} width='100px' height='60px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
								<path
									fill='none'
									stroke='#ffffff'
									strokeWidth='8'
									strokeDasharray='42.76482137044271 42.76482137044271'
									d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z'
									strokeLinecap='round'
									style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}
								>
									<animate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0;256.58892822265625'></animate>
								</path>
							</svg>
						)}
						{!loading && (
							<div className='buttons-c'>
								<input type='submit' disabled={loading} value='ATUALIZAR PERFIL' className='submit' />
							</div>
						)}
					</div>
				</form>
			</Modal>
			<Modal
				handler={modal}
				onRequestClose={() => {
					setModal(false);
					setPin('');
					setOtpAuth('');
				}}
			>
				<form
					className='form'
					onSubmit={(e) => {
						e.preventDefault();
						if (func) {
							const execute = func;
							execute({ ...params, pin, otpAuth });
						} else {
							setModal(false);
							setPin('');
							setOtpAuth('');
						}
					}}
					style={{ alignItems: 'center' }}
				>
					<div>
						<label className='label' style={{ color: '#fff' }} htmlFor='pin'>
							Senha
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<input type='password' name='pswd_fin' id='pswd_fin' className='cnt-input' onChange={(e: any) => setPin(e.target.value)} value={pin} required />
						</div>
						<label className='label' style={{ color: '#fff' }}>
							2FA
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto' }}>
							<input onChange={(e: any) => setOtpAuth(e.target.value)} className='cnt-input' type='text' name='otp' id='token' value={otpAuth} required />
						</div>

						{loading && (
							<svg xmlns='http://www.w3.org/2000/svg' style={{ margin: '0 auto', display: 'block', shapeRendering: 'auto' }} width='100px' height='60px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
								<path
									fill='none'
									stroke='#ffffff'
									strokeWidth='8'
									strokeDasharray='42.76482137044271 42.76482137044271'
									d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z'
									strokeLinecap='round'
									style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}
								>
									<animate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0;256.58892822265625'></animate>
								</path>
							</svg>
						)}
						{!loading && (
							<div className='buttons-c'>
								<input type='submit' disabled={loading} value='EXECUTAR' className='submit' />
								{/* <input type="reset" value="CANCELAR" className="reset" /> */}
							</div>
						)}
					</div>
				</form>
			</Modal>
		</>
	);
}
