/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/AuthContext';
import { api } from '../../services/api';
// import IntlCurrencyInput from 'react-intl-currency-input';

import chevronSVG from '../../assets/img/icons/chevron-select.svg';
import { useForm } from 'react-hook-form';
import { FiCopy, /* FiDollarSign,  */ FiEdit3, FiFileText /* , FiSlash */ } from 'react-icons/fi';
import { Modal } from '../../components/Modal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface IProps {
	select: Function;
}

const statusList: any = {
	a: 'Ativo',
	i: 'Inativo',
};

export function Products({ select }: IProps) {
	const { toggleLoading, loading } = useContext(AuthContext);

	const [products, setAuctions] = useState<Array<any>>([]);
	const [page, setPage] = useState(1);
	const [disablePagination, setDisablePagination] = useState(true);
	const [productEdit, setAuctionEdit] = useState<any>();

	const [modal, setModal] = useState(false);

	const { register, getValues } = useForm<any>();
	const { register: registerEdit, reset: resetEdit, getValues: getValuesEdit } = useForm<any>();

	async function searchAuctions() {
		toggleLoading(true);
		try {
			const response = await api.post('/manager/product/search', { ...getValues(), page: page });
			if (page === 1) {
				setDisablePagination(false);
				setAuctions(response.data);
			} else if (page > 1 && response.data.length > 0) {
				setDisablePagination(false);
				setAuctions([...products, ...response.data]);
			}
			if (response.data.length < 25) {
				setDisablePagination(true);
			}
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		toggleLoading(false);
	}

	async function handleEditAuction(data: any) {
		if (data?.dateStart) {
			data.dateStart = dayjs(new Date(data.dateStart)).utc().format();
		}
		toggleLoading(true);
		try {
			await api.post('/manager/product/update', data);
			toast(`Leilão editado com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			setModal(false);
			resetEdit();
			setAuctionEdit(null);
			if (page == 1) {
				searchAuctions();
			} else {
				setPage(1);
			}
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		toggleLoading(false);
	}

	useEffect(() => {
		searchAuctions();
	}, [page]);

	useEffect(() => {
		if (productEdit) {
			resetEdit({
				productId: productEdit?.id,
				betsUse: productEdit?.betsUse,
				timer: productEdit?.timer,
				mark: productEdit?.mark,
				markPercentage: productEdit?.markPercentage,
				dateStart: dayjs(productEdit?.dateStart).format('YYYY-MM-DD HH:mm'),
			});
			setModal(true);
		}
	}, [productEdit]);

	return (
		<>
			<div className='content-c active' id='content-2'>
				<div className='content'>
					<h1>Produtos</h1>
					<div className='filters'>
						<select className='select' {...register('status')}>
							<option value=''>Todos</option>
							<option value='a'>Ativos</option>
							<option value='i'>Inativos</option>
							<option value='e'>Encerrados</option>
						</select>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								if (page === 1) {
									searchAuctions();
								} else {
									setPage(1);
								}
							}}
						>
							<input id='search' type='text' className='search' placeholder='Search' {...register('search')} />
							<div className='buttons-c'>
								<button type='submit' className='button'>
									<svg className='img' version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 40 40'>
										<path
											fill='var(--white-2)'
											d='M37.9,38c-1.4,1.4-3.7,1.4-5,0l0,0l-6.3-6.3c-7.7,5-18,2.7-23-5S0.9,8.6,8.6,3.6s18-2.7,23,5 c3.6,5.5,3.6,12.5,0,18l6.3,6.3C39.4,34.3,39.4,36.6,37.9,38L37.9,38z M17.7,5.9c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8 s11.8-5.3,11.8-11.8S24.3,5.9,17.7,5.9L17.7,5.9z'
										/>
									</svg>
								</button>
							</div>
						</form>
					</div>
					<div className='table-c'>
						<table className='table'>
							<thead>
								<tr>
									<th>Produto</th>
									<th>Id</th>
									<th>Nome</th>
									<th>Valor do produto</th>
									<th>Status</th>
									<th className='align-right'>Menu</th>
								</tr>
							</thead>
							<tbody>
								{products &&
									products.map((product) => (
										<tr key={product?.id}>
											<td>
												<img src={product?.image} style={{ maxWidth: 100, maxHeight: 100 }} />
											</td>
											<td>{product?.id}</td>
											<td>{String(product?.name).substring(0, 30)}</td>
											<td>
												{new Intl.NumberFormat('pt-br', {
													style: 'currency',
													currency: 'BRL',
												}).format(product?.price ? Number(product?.price) : 0)}
											</td>
											<td>{statusList[product?.status]}</td>
											<td className='align-right'>
												<a title='Descrição' onClick={() => select(product, 'content-3')} className='mini-button'>
													<FiFileText />
												</a>
												<a title='Copiar produto' onClick={() => select({ ...product, id: null, copy: true })} className='mini-button'>
													<FiCopy />
												</a>
												<a title='Editar produto' onClick={() => select({ ...product, copy: false, productId: product?.id })} className='mini-button'>
													<FiEdit3 />
												</a>
											</td>
										</tr>
									))}
							</tbody>
						</table>
						<div className='loader-c'>
							<button disabled={disablePagination || loading} onClick={() => setPage(page + 1)} className={`loader ${disablePagination == true ? 'hidden' : ''}`}>
								<img src={chevronSVG} className='icon' />
								Carregar Mais
							</button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				handler={modal}
				onRequestClose={() => {
					setModal(false);
				}}
			>
				<form
					className='form'
					onSubmit={(e) => {
						e.preventDefault();
						handleEditAuction(getValuesEdit());
					}}
					style={{ alignItems: 'center' }}
				>
					<div>
						<label className='label' style={{ color: '#fff' }} htmlFor='dateStart'>
							Data de início
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<input id='dateStart' className='cnt-input' type='datetime-local' {...registerEdit('dateStart')} />
						</div>
						<label className='label' style={{ color: '#fff' }} htmlFor='referral'>
							Corretagem
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<select className='cnt-input' style={{ width: '100%', borderRadius: 8 }} {...registerEdit('mark')}>
								<option value='y'>Ativada</option>
								<option value='n'>Desabilitada</option>
							</select>
						</div>
						{productEdit?.mark === 'y' && (
							<>
								<label className='label' style={{ color: '#fff' }} htmlFor='markPercentage'>
									% do corretor
								</label>
								<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
									<input id='referralPercentage' className='cnt-input' type='number' min={1} max={50} {...registerEdit('markPercentage')} />
								</div>
							</>
						)}
						<label className='label' style={{ color: '#fff' }} htmlFor='markPercentage'>
							Cronometro
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<input id='referralPercentage' className='cnt-input' type='number' min={15} max={900} {...registerEdit('timer')} />
						</div>
						<label className='label' style={{ color: '#fff' }} htmlFor='markPercentage'>
							Qnt. de lances
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<input id='referralPercentage' className='cnt-input' type='number' min={1} max={5} {...registerEdit('betsUse')} />
						</div>
						<label className='label' style={{ color: '#fff' }} htmlFor='pin'>
							Senha
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<input type='password' id='pin' className='cnt-input' {...registerEdit('pin')} required />
						</div>

						<label className='label' style={{ color: '#fff' }}>
							2FA
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto' }}>
							<input className='cnt-input' type='text' {...registerEdit('otpAuth')} id='otpAuth' required />
						</div>

						{loading && (
							<svg xmlns='http://www.w3.org/2000/svg' style={{ margin: '0 auto', display: 'block', shapeRendering: 'auto' }} width='100px' height='60px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
								<path
									fill='none'
									stroke='#ffffff'
									strokeWidth='8'
									strokeDasharray='42.76482137044271 42.76482137044271'
									d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z'
									strokeLinecap='round'
									style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}
								>
									<animate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0;256.58892822265625'></animate>
								</path>
							</svg>
						)}
						{!loading && (
							<div className='buttons-c'>
								<input type='submit' disabled={loading} value='EDITAR' className='submit' />
								{/* <input type="reset" value="CANCELAR" className="reset" /> */}
							</div>
						)}
					</div>
				</form>
			</Modal>
		</>
	);
}
