/* eslint-disable max-len */
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Modal } from '..';
import { api } from '../../../services/api';


interface ModalProps {
  handler: boolean;
  overlayOpacity?: number;
  onRequestClose: () => void;
  noPadding?: boolean;
  noClose?: boolean;
  width?: number;
  user?: {
    user: number;
    userName: string;
    bet?: string;
  }
}


export function BlackListModal({ 
  handler, 
  overlayOpacity, 
  onRequestClose, 
  noPadding, 
  noClose = false, 
  width,
  user
}: ModalProps) {
  const [loading, setLoading] = useState(false);
  const { register, reset, getValues } = useForm<any>();

  useEffect(() => {
    if (!user?.user || Number(user?.user) < 1) {
      onRequestClose()
    }
    reset({
      user: Number(user?.user),
      userName: user?.userName,
      bet: user?.bet,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // --- Insert user in blacklist --- //
  async function handleInsertUserInBlacklist() {
    setLoading(true)
    try {
      await api.post("/manager/users/blacklist/insert", { ...getValues() })
      toast(`Usuário inserido com sucesso`, {
        style: { backgroundColor: "#00A300", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
      onRequestClose()
      reset({})
    } catch (err: any) {
      toast(`${err?.response?.data?.message}`, {
        style: { backgroundColor: "#f00", color: "#fff", position: "absolute" },
        progressStyle: { background: "darkgreen" },
      });
    }
    setLoading(false)
  }

  return(
    <Modal
        handler={handler}
        onRequestClose={onRequestClose}
        overlayOpacity={overlayOpacity}
        noPadding={noPadding}
        noClose={noClose}
        width={width}
      >
        <form className='form' onSubmit={(e) => {
          e.preventDefault()
          handleInsertUserInBlacklist()
          }} style={{alignItems: 'center'}}>
          <div>
            <label className='label' style={{color: "#fff"}} htmlFor="payment">Usuário</label>
            <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
            <input
                type="text"
                id="userName"
                className='cnt-input'
                disabled
                {...register('userName')}
              />
            </div>
            <label className='label' style={{color: "#fff"}} htmlFor="bet">Bloquear lances</label>
            <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
              <select className='cnt-input' style={{width: '100%'}} {...register('bet')} required>
                <option value="n">Não</option>
                <option value="y">Sim</option>
              </select>
            </div>
            {/* <label className='label' style={{color: "#fff"}} htmlFor="auction">Bloquear leilões</label>
            <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
              <select className='cnt-input' style={{width: '100%'}} {...register('auction')} required>
                <option value="n">Não</option>
                <option value="y">Sim</option>
              </select>
            </div> */}
            {/* <label className='label' style={{color: "#fff"}} htmlFor="withdraw">Bloquear saques</label>
            <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
              <select className='cnt-input' style={{width: '100%'}} {...register('withdraw')} required>
                <option value="n">Não</option>
                <option value="y">Sim</option>
              </select>
            </div> */}
            <label className='label' style={{color: "#fff"}} htmlFor="pin" >Senha</label>
            <div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
              <input
                type="password"
                id="pswd_fin"
                className='cnt-input'
                required
                {...register('pin')}
              />
            </div>
            <label className="label" style={{color: "#fff"}}>2FA</label>
            <div style={{ maxWidth: '400px', margin: '0 auto' }}>
              <input
                className='cnt-input'
                type="text"
                id="token"
                required
                {...register('otpAuth')}
              />
              
            </div>

            {
              loading &&
              <svg xmlns="http://www.w3.org/2000/svg" style={{margin: '0 auto', display: 'block', shapeRendering: 'auto'}} width="100px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <path fill="none" stroke="#ffffff" strokeWidth="8" strokeDasharray="42.76482137044271 42.76482137044271" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" strokeLinecap="round" style={{transform: 'scale(0.8)', transformOrigin: '50px 50px'}}>
                  <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"></animate>
                </path>
              </svg>
            }
            {
              !loading &&
              <div className="buttons-c">
                <input type="submit" disabled={loading} value="INSERIR" className="submit" />
                {/* <input type="reset" value="CANCELAR" className="reset" /> */}
              </div>
            }
          </div>
        </form>
      </Modal>
  )
}