/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiFile } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Modal } from '../../components/Modal';
import { api } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import fileImage from '../../assets/img/file.jpg';

interface IProps {
	testimony: any;
	updateTestimony: Function;
}

export function CreateTestimony({ testimony, updateTestimony }: IProps) {
	const { toggleLoading } = useContext(AuthContext);

	const [loading, setLoading] = useState(false);
	const [modalUpdate, setModalUpdate] = useState(false);
	const [img, setImg] = useState<any>();
	const [imgName, setImgName] = useState('');

	const { register, getValues, reset } = useForm<any>({
		mode: 'onSubmit',
		reValidateMode: 'onBlur',
	});

	// --- Create testimony --- //
	async function handleCreateTestimony(data: any) {
		if (!img) {
			toast(`Não foi possivel encontrar sua imagem`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			return;
		}

		toggleLoading(true);
		setLoading(true);
		data.file = JSON.stringify({ base64: img });

		try {
			await api.post('/manager/testimony/create', data);
			toast(`Depoimento criado com sucesso com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			setModalUpdate(false);
			reset();
			setImgName('');
			setImg(null);
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
		toggleLoading(false);
	}

	// --- Update testimony --- //
	async function handleUpdateTestimony(data: any) {
		if (!img) {
			toast(`Não foi possivel encontrar sua imagem`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			return;
		}

		toggleLoading(true);
		setLoading(true);

		if (img && imgName) {
			data.file = JSON.stringify({ base64: img });
		}

		try {
			const response = await api.post('/manager/testimony/update', { ...data, plan: data.planId });
			toast(`Depoimento atualizado com sucesso com sucesso`, {
				style: { backgroundColor: '#00A300', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
			setModalUpdate(false);
			updateTestimony({ ...response.data, planId: response.data.id, copy: false });
		} catch (err: any) {
			toast(`${err?.response?.data?.message}`, {
				style: { backgroundColor: '#f00', color: '#fff', position: 'absolute' },
				progressStyle: { background: 'darkgreen' },
			});
		}
		setLoading(false);
		toggleLoading(false);
	}

	const imageHandler = (e: any) => {
		const reader = new FileReader();
		reader.onload = () => {
			if (reader?.readyState === 2) {
				setImg(reader.result);
			}
		};
		setImgName(e?.target?.files[0]?.name);
		reader.readAsDataURL(e?.target?.files[0]);
	};

	// --- Get image by url and convert in base64 --- //
	function toDataUrl(url: string, callback: Function) {
		var xhr = new XMLHttpRequest();
		xhr.onload = function () {
			var reader = new FileReader();
			reader.onloadend = function () {
				callback(reader.result);
			};
			reader.readAsDataURL(xhr.response);
		};
		xhr.open('GET', url);
		xhr.responseType = 'blob';
		xhr.send();
	}

	useEffect(() => {
		if (testimony && testimony?.id) {
			if (testimony?.copy === true) {
				toDataUrl(testimony?.image, setImg);
			} else {
				setImg(testimony?.image);
			}
			setImgName('');
			reset({
				...testimony,
				testimonyId: testimony?.copy === true ? null : testimony?.id,
				id: testimony?.copy === true ? null : testimony?.id,
			});
		}
	}, [testimony]);

	return (
		<>
			<div className='content-c active' id='content-2'>
				<div className='content'>
					<h1>{testimony?.id && testimony?.copy !== true ? 'Editar depoimento' : 'Criar depoimento'}</h1>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							setModalUpdate(true);
						}}
						className='form'
						acceptCharset='utf-8'
						method='post'
					>
						<div className='table-c'>
							<table className='table'>
								<tbody>
									<tr>
										<td colSpan={2} className='align-left' style={{ borderWidth: 1 }}>
											Imagens do depoimento
										</td>
									</tr>
									<tr>
										<td colSpan={2} className='align-left' style={{ borderWidth: 1 }}>
											<div className='form'>
												<div className='input file wd-20'>
													<label htmlFor='file-1' style={{ cursor: 'pointer' }}>
														<img id='imageId' src={img || fileImage} className='img' style={{ borderColor: '#555', borderWidth: 1, height: '100%', width: '100%', position: 'relative' }} />
													</label>
													<input disabled={testimony?.id && !testimony?.copy ? true : false} id='file-1' type='file' className='input-f' accept='image/*' onChange={imageHandler} />
													<label htmlFor='file-1' className='file-name'>
														<FiFile className='icon' />
														<label htmlFor='file-1' className='text'>
															{imgName ? (imgName.length > 25 ? `${imgName.split('.')[0].substring(0, 25)}...*.${imgName.split('.')[1]}` : imgName) : 'Selecionar outra imagem'}
														</label>
													</label>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='input-c'>
							<input type='text' title='Video' className='input wd-66' placeholder='Link do video' {...register('video')} />
							<input type='number' title='stars' required className='input wd-33' placeholder='Estrelas' min={1} max={5} {...register('stars')} />
						</div>
						<input type='text' title='Titulo' required className='input wd-100' placeholder='Titulo' minLength={5} {...register('title')} />
						<textarea title='Descrição' className='input wd-100' placeholder='Descrição' style={{ minHeight: 120 }} minLength={3} maxLength={230} {...register('description')} />
						<div className='buttons-c'>
							<input type='submit' value={testimony?.id ? 'ATUALIZAR' : 'CRIAR'} className='submit' />
							{testimony?.id && (
								<input
									type='button'
									onClick={() => {
										reset();
										updateTestimony();
									}}
									value='LIMPAR CAMPOS'
									className='submit'
									style={{ marginLeft: 10 }}
								/>
							)}
						</div>
					</form>
				</div>
			</div>
			<Modal
				handler={modalUpdate}
				onRequestClose={() => {
					setModalUpdate(false);
				}}
			>
				<form
					className='form'
					onSubmit={(e) => {
						e.preventDefault();
						const values = getValues();
						if (!testimony?.id || testimony?.copy === true) {
							handleCreateTestimony(getValues());
						} else if (values?.testimonyId) {
							handleUpdateTestimony(values);
						} else {
						}
					}}
					style={{ alignItems: 'center' }}
				>
					<div>
						<label className='label' style={{ color: '#fff' }} htmlFor='pin'>
							Senha
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto', marginBottom: 15 }}>
							<input type='password' id='pin' className='cnt-input' {...register('pin')} />
						</div>
						{/* <label className='label' style={{ color: '#fff' }}>
							2FA
						</label>
						<div style={{ maxWidth: '400px', margin: '0 auto' }}>
							<input className='cnt-input' type='text' id='token' {...register('otpAuth')} />
						</div> */}

						{loading && (
							<svg xmlns='http://www.w3.org/2000/svg' style={{ margin: '0 auto', display: 'block', shapeRendering: 'auto' }} width='100px' height='60px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
								<path
									fill='none'
									stroke='#ffffff'
									strokeWidth='8'
									strokeDasharray='42.76482137044271 42.76482137044271'
									d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z'
									strokeLinecap='round'
									style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}
								>
									<animate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0;256.58892822265625'></animate>
								</path>
							</svg>
						)}
						{!loading && (
							<div className='buttons-c'>
								<input type='submit' disabled={loading} value={testimony?.testimonyId ? 'ATUALIZAR DEPOIMENTO' : 'CRIAR DEPOIMENTO'} className='submit' />
							</div>
						)}
					</div>
				</form>
			</Modal>
		</>
	);
}
