/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FiEye } from 'react-icons/fi';
import { Modal } from '../Modal';
import { useEffect, useState } from 'react';

interface IProps {
	contacts: Array<any>;
}

export function WebContactsTable({ contacts }: IProps) {
	const [modal, setModal] = useState(false);
	const [select, setSelect] = useState<any>();

	useEffect(() => {
		if (select?.name) {
			setModal(true);
		}
	}, [select]);
	return (
		<div className='table-c'>
			<table className='table'>
				<thead>
					<tr>
						<th>Id</th>
						<th>Nome</th>
						<th>Telefone</th>
						<th>E-mail</th>
						<th className='align-right'>Menu</th>
					</tr>
				</thead>
				<tbody>
					{contacts &&
						contacts.map((contact) => (
							<tr key={contact?.id}>
								<td>{contact?.id}</td>
								<td>{contact?.name}</td>
								<td>{contact?.phone}</td>
								<td>{contact?.email}</td>
								<td className='align-right'>
									<a title='Ver mensagem' onClick={() => setSelect(contact)} className='mini-button'>
										<FiEye />
									</a>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			<Modal
				handler={modal}
				onRequestClose={() => {
					setModal(false);
				}}
				width={80}
			>
				<form
					className='form'
					onSubmit={(e) => {
						e.preventDefault();
					}}
					style={{ alignItems: 'center' }}
				>
					<div style={{ width: '100%', maxWidth: '100%', margin: '0 auto', marginBottom: 15 }}>
						<label className='label' style={{ color: '#fff' }} htmlFor='message'>
							Menssagem
						</label>
						<div style={{ width: '100%', maxWidth: '100%', margin: '0 auto', marginBottom: 15 }}>
							<textarea id='message' disabled value={select?.message} style={{ width: '100%', display: 'flex', flex: 1, minHeight: '30vh', color: '#000' }} />
						</div>
					</div>
				</form>
			</Modal>
		</div>
	);
}
