import React from 'react';
import './assets/main.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import { AuthProvider } from './contexts/AuthContext';
import { SocketProvider } from './contexts/SocketContext';
import { Loading } from './components/Loading';

function App() {
  return (
    <AuthProvider>
      {/* <SocketProvider> */}
        <BrowserRouter>
          <Routes />
          <ToastContainer 
            autoClose={5000}
            closeOnClick
          />
          <Loading />
        </BrowserRouter>
      {/* </SocketProvider> */}
    </AuthProvider>
  );
}

export default App;
