/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useState } from 'react';
import { Header } from '../../components/header';
import { Deposits } from './Deposits';
// import { Bonus } from './Bonus';
import { Coupons } from './Coupons';

export function Financial() {
	const [content, setContent] = useState('content-1');

	return (
		<>
			<Header />

			<div className='location'>
				<span className='page'>Financeiro » </span>
			</div>

			<div className='tab-c'>
				<a onClick={() => setContent('content-1')} className={`tab ${content === 'content-1' ? 'active' : ''}`}>
					Depositos
				</a>
				{/* <a onClick={() => setContent('content-2')} className={`tab ${content === "content-2" ? "active": ""}`}>Saques</a> */}
				{/* <a onClick={() => setContent('content-3')} className={`tab ${content === "content-3" ? "active": ""}`}>Bonus</a> */}
				<a onClick={() => setContent('content-4')} className={`tab ${content === 'content-4' ? 'active' : ''}`}>
					Cupons
				</a>
			</div>

			{content === 'content-1' && <Deposits />}
			{/* {content === "content-2" && <Payouts />} */}
			{/* {content === "content-3" && <Bonus />} */}
			{content === 'content-4' && <Coupons />}
		</>
	);
}
