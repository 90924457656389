import { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Login } from "../pages/Login";
import { AuthRoutes } from "./AuthRoutes";
import 'react-select-plus/dist/react-select-plus.css';




export default function Routes() {
  const { admin } = useContext(AuthContext);
  const [user, setUser] = useState(false);

  return(
    !admin.id ? <Login /> : <AuthRoutes />
    // <Teste />
  )
}